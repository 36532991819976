import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Calendar, globalizeLocalizer } from 'react-big-calendar';
import globalize from 'globalize';
import './styleCalenderView.css';
import WeekView from '../Weekview/WeekView';
import moment from 'moment';
import axios from 'axios';
import BasicTable from '../Dayview/DayView';
import { useDispatch, useSelector } from 'react-redux';
import { setView, setAddTask, setFullDate, setDate, setSearchedDate, setSearchedMonth, setWeekData, setMonthData, setTaskEmp, setWeekAddTaskFlag, setMonthAddTaskFlag } from '../../../erpStore/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Calendar as RouteCalender } from 'primereact/calendar';

const localizer = globalizeLocalizer(globalize);

const CalenderView = () => {
    const culture = globalize.culture('en-US');
    culture.calendar.firstDay = 1;
    const dispatch = useDispatch();
    const empView = useSelector((state) => state.data.empView)
    const taskUpdate = useSelector((state) => state.data.newTask)
    const viewM = useSelector((state) => state.data.view)
    const self = useSelector((state) => state.data.selfData)
    const empInfo = useSelector((state) => state.data.empInfo)
    const taskEmp = useSelector((state) => state.data.taskEmp)
    // const selfInfo = empView ? empInfo : self
    const selfInfo = empView ? taskEmp : self
    const url = useSelector((state) => state.data.apiUrl)
    const [dayView, setDayView] = useState(true);
    const monthData = useSelector((state) => state.data.monthData);
    // const [monthData, setMonthData] = useState({});
    const searchedTaskMonth = useSelector((state) => state.data.searchedTaskMonth)
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [isDateRoute, setIsDateRoute] = useState(false)
    const mothDateRouteWrapper = useRef(null)
    let nDate = []
    const dispatchAllTask = useSelector((state)=> state.data.dispatchAllTask)
    const monthAddTaskFlag = useSelector((state) => state.data.monthAddTaskFlag)
    const weekAddTaskFlag = useSelector((state) => state.data.weekAddTaskFlag)
    const levelMonthAddTaskFlag = useSelector((state) => state.data.levelMonthAddTaskFlag)
    const covertStringFormat = (stringFormat) => {
        return stringFormat === 'FULLDAY' ? 'Full Day Leave' : stringFormat === 'HALFDAY1' ? 'First Half Leave' : 'Second Half Leave'
    }
    
    useEffect(() => {
        if (window.localStorage.getItem("myHeaders") === "") {
            navigate('/login')
            return
        }
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (viewM === "month") {
            if (nDate) {
                if (empView || levelMonthAddTaskFlag) {
                    if (parseInt(nDate[3]) && parseInt(getMonthNumber(nDate[1]))) {
                        axios.get(`${url}task/employee/${selfInfo.userId}/get/by/month?year=${parseInt(nDate[3])}&month=${parseInt(getMonthNumber(nDate[1]))}`, {
                            headers: storedHeaders,
                        })
                            .then((response) => {
                                setLoader(false)
                                dispatch(setMonthData(response.data))
                                dispatch(setMonthAddTaskFlag(false))
                            })
                            .catch((error) => {
                                if (error.response && error.response.status === 500) {
                                    navigate("/server-error")
                                }
                            })
                        return
                    }

                }
                let data = {
                    year: parseInt(nDate[3]),
                    month: parseInt(getMonthNumber(nDate[1])),
                    empId: parseInt(selfInfo.empId)
                };
                if (data.month && data.year && data.empId) {
                                
                    axios.post(`${url}task/get/by/month`, data, {
                        headers: storedHeaders,
                    })
                        .then(response => {
                            setLoader(false)
                            dispatch(setMonthAddTaskFlag(false))
                            dispatch(setMonthData(response.data))
                            })
                        .catch(error => {
                        })
                }
            }
        }
        
    }, [selfInfo, taskUpdate, viewM, dispatchAllTask, monthAddTaskFlag, levelMonthAddTaskFlag ])


    const FetachWeekData = () => {
        const dateString = new Date().toString();
            const dateSplit = dateString.split(" ");
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek(),
                empId: parseInt(selfInfo.empId)
            };

            axios.post(`${url}task/get/by/week`, reqBody, { headers: storedHeaders })
            .then(response => {
                if (response.status === 200) { // use response.status instead of response.request.status
                    dispatch(setWeekData(response.data));
                    dispatch(setWeekAddTaskFlag(false))
                }
            })
            .catch(error => {
            });
    }


    useEffect(() => {
        if(viewM === 'week' || weekAddTaskFlag === true){
            FetachWeekData()
        }
    }, [viewM, taskUpdate, weekAddTaskFlag])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mothDateRouteWrapper && mothDateRouteWrapper.current && !mothDateRouteWrapper.current.contains(event.target)) {
                setIsDateRoute(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const getMonthNumber = (monthName) => {
        if (monthName) {
            const monthMoment = moment().month(monthName).startOf('month');
            return monthMoment.format('MM');
        }
    };

    const navigateNextMonth = (onNavigate) => {
        setLoader(true)
        onNavigate("NEXT")
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (nDate) {
            if (empView) {
                axios.get(`${url}task/employee/${selfInfo.userId}/get/by/month?year=${parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? parseInt(nDate[3]) + 1 : parseInt(nDate[3])}&month=${parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? 1 : parseInt(getMonthNumber(nDate[1])) + 1}`, {
                    headers: storedHeaders,
                })
                    .then((response) => {
                        setLoader(false)
                        dispatch(setSearchedMonth([]))
                        dispatch(setMonthData(response.data))
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 500) {
                            navigate("/server-error")
                        }
                    })
                return
            }
            let data = {
                year: parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? parseInt(nDate[3]) + 1 : parseInt(nDate[3]),
                month: parseInt(getMonthNumber(nDate[1])) + 1 === 13 ? 1 : parseInt(getMonthNumber(nDate[1])) + 1,
                empId: parseInt(selfInfo.empId)
            };
            axios.post(`${url}task/get/by/month`, data, {
                headers: storedHeaders,
            })
                .then(response => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch(error => {
                })
        }
    }
    const navigatePrevMonth = (onNavigate) => {
        setLoader(true)
        onNavigate("PREV")
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        if (nDate) {
            if (empView) {
                axios.get(`${url}task/employee/${selfInfo.userId}/get/by/month?year=${parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? parseInt(nDate[3]) - 1 : parseInt(nDate[3])}&month=${parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? 12 : parseInt(getMonthNumber(nDate[1])) - 1}`, {
                    headers: storedHeaders,
                })
                    .then((response) => {
                        setLoader(false)
                        dispatch(setMonthData(response.data))
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 500) {
                            navigate("/server-error")
                        }
                    })
                return
            }
            let data = {
                year: parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? parseInt(nDate[3]) - 1 : parseInt(nDate[3]),
                month: parseInt(getMonthNumber(nDate[1])) - 1 === 0 ? 12 : parseInt(getMonthNumber(nDate[1])) - 1,
                empId: parseInt(selfInfo.empId)
            };
            axios.post(`${url}task/get/by/month`, data, {
                headers: storedHeaders,
            })
                .then(response => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch(error => {
                })
        }
    }
    const onNavigateToday = (onNavigate) => {
        setLoader(true)
        onNavigate('TODAY')
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        nDate = new Date().toString().split(" ")
        if (nDate) {
            if (empView) {
                axios.get(`${url}task/employee/${selfInfo.userId}/get/by/month?year=${parseInt(nDate[3])}&month=${parseInt(getMonthNumber(nDate[1]))}`, {
                    headers: storedHeaders,
                })
                    .then((response) => {
                        setLoader(false)
                        dispatch(setSearchedMonth([]))
                        dispatch(setMonthData(response.data))
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 500) {
                            navigate("/server-error")
                        }
                    })
                return
            }
            let data = {
                year: parseInt(nDate[3]),
                month: parseInt(getMonthNumber(nDate[1])),
                empId: parseInt(selfInfo.empId)
            };
            axios.post(`${url}task/get/by/month`, data, {
                headers: storedHeaders,
            })
                .then(response => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch(error => {
                })
        }
    }
    const getStatusColor = useMemo(
        () => (taskStatus) => {
            switch (taskStatus) {
                case 'Not Started':
                    return 'rgba(0, 0, 0, 1)';
                case 'Done':
                    return 'rgba(1, 159, 55, 1)';
                case 'In Progress':
                    return 'rgba(0, 95, 182, 1)';
                default:
                    return '';
            }
        },
        []
    );

    //comparison of date to date is less than joining date for add task condition 
    const isDateLessThanCurrent = (date) => {
        const givenDate = moment(date.value); // Convert string to Date object
        const currentDate = moment(selfInfo.empJoiningDate); // Get current date
        // Compare the dates
        return givenDate < currentDate;
    };

    const renderCustomMonthCell = (date) => {
        if (!date) {
            return <div className='rbc-day-bg'>No date</div>;
        }
        const dateString = date.value + "";
        const dateSplit = dateString.split(" ");
        const calendarDay = dateSplit[2].toString();
        const newDate = new Date();
        const listDate = newDate.toString().split(" ");
        const getToday = listDate[2].toString();
        const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
        const todayMonth = listDate[1].toString() + listDate[3].toString();
        const isToday = calendarDay === getToday && currentMonth === todayMonth;
        const gtDate = (parseInt(getMonthNumber(dateSplit[1])) === parseInt(getMonthNumber(listDate[1])) && parseInt(dateSplit[2]) < parseInt(listDate[2]));
        let monthNum = getMonthNumber(dateSplit[1]);
        const fullDate = dateSplit[3] + "-" + monthNum + "-" + dateSplit[2].padStart(2, '0');
        if (fullDate === (dateSplit[3] + "-" + monthNum + "-" + 15)) {
            dispatch(setSearchedDate(fullDate))
        }

        const taskList = searchedTaskMonth.length > 0 ? searchedTaskMonth[fullDate] : monthData[fullDate];

        if (!labelData[1].includes(dateSplit[1])) {
            if (dateSplit[0] === "Sun") {
                document.querySelector(".rbc-month-header").children[6].setAttribute("style", "min-width:4.8%")
                return <div className='rbc-day-bg off-day sunday-block'><div className='dateContainer'>{dateSplit[2]}</div></div>;
            }
            return <div className='rbc-day-bg off-day'></div>;
        }

        if (dateSplit[0] === "Sun") {
            return (
                <div className='rbc-day-bg sunday-block'>
                    <div className='dateContainer' onClick={monthData !== null ? () => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        setDayView(!dayView);
                        dispatch(setView('day'))
                        window.sessionStorage.setItem("view", 'day')
                    } : null}>{dateSplit[2]}</div>
                    <div className='innerDiv-addTask-sunday' onClick={(e) => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        dispatch(setAddTask(true))
                        dispatch(setDate(fullDate))
                    }} style={{ cursor: 'pointer' }}>
                        <div className='add-task-container-sunday' style={{ display: isDateLessThanCurrent(date) ? "none" : "flex" }}>
                            <svg width="10" height="10" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.4 3C0.4 1.56406 1.56406 0.4 3 0.4C4.43594 0.4 5.6 1.56406 5.6 3C5.6 4.43594 4.43594 5.6 3 5.6C1.56406 5.6 0.4 4.43594 0.4 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM3 1.63636C3.15062 1.63636 3.27273 1.72358 3.27273 1.83117V2.72727H4.16883C4.27642 2.72727 4.36364 2.84938 4.36364 3C4.36364 3.15062 4.27642 3.27273 4.16883 3.27273H3.27273V4.16883C3.27273 4.27642 3.15062 4.36364 3 4.36364C2.84938 4.36364 2.72727 4.27642 2.72727 4.16883V3.27273H1.83117C1.72358 3.27273 1.63636 3.15062 1.63636 3C1.63636 2.84938 1.72358 2.72727 1.83117 2.72727H2.72727V1.83117C2.72727 1.72358 2.84938 1.63636 3 1.63636Z" fill="#263238" />
                            </svg>
                            <p>Add Task</p>
                        </div>
                    </div>
                </div>
            );
        }
        if (loader) {
            return (
                <div className='rbc-day-bg'>
                    <div className='innerDiv-day'
                    // style={{
                    //     background: isToday ? '#FFF6DF' : 'none', opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1"
                    // }}
                    ><div className='innerDiv-addTask' style={{ borderBottom: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "null" : "null", cursor: 'pointer' }}>
                            <div className='add-task-container' style={{ display: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "flex" : "flex" }}>
                                <div class="loader-month loader-addTask"></div>
                            </div>
                        </div>
                        <div className='innerDivTask task-loader' onClick={() => {
                            const previousDate = moment(date.value)
                            dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                            setDayView(!dayView);
                            dispatch(setView('day'))
                            window.sessionStorage.setItem("view", 'day')
                        }}>
                            <div class="loader-month"></div>
                            <div class="loader-month"></div>
                            <div class="loader-month"></div>
                        </div>
                    </div>
                </div>
            );
        }
        if (empView === true) {
            return (
                <div className='rbc-day-bg'>
                    <div className='innerDiv-day'
                        style={{
                            background: isToday ? '#FFF6DF' :  taskList?.holiday !== null  ?  '#E3E3E3' : taskList?.leave !== null ?   '#FFDFDF' : 'none' , opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1"
                        }}>     
                            <div className='innerDiv-addTask' onClick={(e) => {
                                const previousDate = moment(date.value)
                                dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                                dispatch(setAddTask(true))
                                dispatch(setDate(fullDate))
                            }} style={{ cursor: 'pointer' }}>
                                <div className='add-task-container' style={{ display: isDateLessThanCurrent(date) ? "none" : "flex" }}>
                                    <svg width="10" height="10" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.4 3C0.4 1.56406 1.56406 0.4 3 0.4C4.43594 0.4 5.6 1.56406 5.6 3C5.6 4.43594 4.43594 5.6 3 5.6C1.56406 5.6 0.4 4.43594 0.4 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM3 1.63636C3.15062 1.63636 3.27273 1.72358 3.27273 1.83117V2.72727H4.16883C4.27642 2.72727 4.36364 2.84938 4.36364 3C4.36364 3.15062 4.27642 3.27273 4.16883 3.27273H3.27273V4.16883C3.27273 4.27642 3.15062 4.36364 3 4.36364C2.84938 4.36364 2.72727 4.27642 2.72727 4.16883V3.27273H1.83117C1.72358 3.27273 1.63636 3.15062 1.63636 3C1.63636 2.84938 1.72358 2.72727 1.83117 2.72727H2.72727V1.83117C2.72727 1.72358 2.84938 1.63636 3 1.63636Z" fill="#263238" />
                                    </svg>
                                    <p>Add Task</p>
                                </div>
                            </div>

                            { taskList?.holiday !== null && 
                          <div className='levelInnerDivTaskCount' onClick={() => {
                                const previousDate = moment(date.value)
                                dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                                setDayView(!dayView);
                                dispatch(setView('day'))
                                window.sessionStorage.setItem("view", 'day')
                            }}>
                                    <div className='levelTaskListCountContainer'>
                                            <div className='levelHolidayCaRD'>
                                            {taskList?.holiday?.holidayTitle.length > 15 ? taskList?.holiday?.holidayTitle.substring(0, 25) + '...' : taskList?.holiday?.holidayTitle}                                            </div>
                                    </div>
                            </div>
                        }

                        { taskList?.holiday === null && taskList?.leave !== null &&   
                          <div className='levelInnerDivTaskCount' onClick={() => {
                                const previousDate = moment(date.value)
                                dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                                setDayView(!dayView);
                                dispatch(setView('day'))
                                window.sessionStorage.setItem("view", 'day')
                            }}>
                                    <div className='levelTaskListCountContainer'>
                                        <div  className='taslLeaveStatuDiv' style={{ backgroundColor:  taskList.leave.leaveFormat === 'FULLDAY'  ? '#fff' : '#E8E2F3' }} >
                                            <div className='leaveStatusDisc' style={{ backgroundColor:  taskList.leave.leaveFormat === 'FULLDAY'  ? '#EA1616' : '#623FAD' }}></div>
                                            <div style={{ color: taskList.leave?.leaveFormat === 'FULLDAY' ? '#EA1616' : '#263238'}}>{covertStringFormat(taskList.leave.leaveFormat)}</div>
                                        </div>
                                    </div>
                            </div>
                        }

                        { taskList?.holiday === null && 
                          <div className='levelInnerDivTaskCount' onClick={() => {
                                const previousDate = moment(date.value)
                                dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                                setDayView(!dayView);
                                dispatch(setView('day'))
                                window.sessionStorage.setItem("view", 'day')
                            }}>
                                    <div className='levelTaskListCountContainer'>
                                        <div className='taskDivCount levelTaskCountDivContainer' style={{ background: "rgba(98, 63, 173, 0.15)" }}>
                                            <div className='taskDiv-half-container'>
                                                <div title="Assign" className="status" style={{ backgroundColor: getStatusColor("done") }}></div>
                                                <div className='task-holder'>
                                                    <p title="Assign">Assign</p>
                                                </div>
                                            </div>
                                            <div className='priority-container'>
                                                <p className='priority'>{taskList?.taskAssign}</p>
                                            </div>
                                        </div>
                                        {taskList?.taskDone >= 0 ?
                                            <div className='taskDivCount' style={{ background: "rgba(1, 159, 55, 0.15)" }}>
                                                <div className='taskDiv-half-container'>
                                                    <div title="Assign" className="status" style={{ backgroundColor: getStatusColor("done") }}></div>
                                                    <div className='task-holder'>
                                                        <p title="Assign">Completed</p>
                                                    </div>
                                                </div>
                                                <div className='priority-container'>
                                                    <p className='priority'>{taskList?.taskDone}</p>
                                                </div>
                                            </div> : null
                                        }
                                        {taskList?.task?.taskDue >= 0 ?
                                            <div className='taskDivCount' style={{ background: "rgba(234, 22, 22, 0.15)" }}>
                                                <div className='taskDiv-half-container'>
                                                    <div title="Assign" className="status" style={{ backgroundColor: getStatusColor("done") }}></div>
                                                    <div className='task-holder'>
                                                        <p title="Assign">Due</p>
                                                    </div>
                                                </div>
                                                <div className='priority-container'>
                                                    <p className='priority'>{taskList?.taskDue}</p>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        {isToday ?
                                            <div className='taskDivCount' style={{ background: "rgba(0, 95, 182, 0.15)" }}>
                                                <div className='taskDiv-half-container'>
                                                    <div title="Assign" className="status" style={{ backgroundColor: getStatusColor("done") }}></div>
                                                    <div className='task-holder'>
                                                        <p title="Assign">In Progress</p>
                                                    </div>
                                                </div>
                                                <div className='priority-container'>
                                                    <p className='priority'>{taskList?.taskProgress}</p>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                            </div>
                        }
                    </div>
                </div>
            );
        }
        return (
            <div className='rbc-day-bg'>

                <div className='innerDiv-day'
                    style={{
                        background: isToday ? '#FFF6DF' : taskList?.holiday === null ?  taskList?.leave !== null ? '#FFDFDF' : 'none' : '#E3E3E3', opacity: parseInt(dateSplit[3]) < parseInt(listDate[3]) || parseInt(getMonthNumber(dateSplit[1])) < parseInt(getMonthNumber(listDate[1])) || gtDate ? "1" : "1",
  
                    }}
                >
                    <div className='innerDiv-addTask'  onClick={(e) => {
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        dispatch(setAddTask(true))
                        dispatch(setDate(fullDate))
                    }} style={{ borderBottom: isDateLessThanCurrent(date) ? "0px solid" : "null", cursor: 'pointer'}}>
                        <div className='add-task-container' style={{ display: isDateLessThanCurrent(date) ? "none" : "flex" }}>
                            <svg width="10" height="10" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.4 3C0.4 1.56406 1.56406 0.4 3 0.4C4.43594 0.4 5.6 1.56406 5.6 3C5.6 4.43594 4.43594 5.6 3 5.6C1.56406 5.6 0.4 4.43594 0.4 3ZM3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0ZM3 1.63636C3.15062 1.63636 3.27273 1.72358 3.27273 1.83117V2.72727H4.16883C4.27642 2.72727 4.36364 2.84938 4.36364 3C4.36364 3.15062 4.27642 3.27273 4.16883 3.27273H3.27273V4.16883C3.27273 4.27642 3.15062 4.36364 3 4.36364C2.84938 4.36364 2.72727 4.27642 2.72727 4.16883V3.27273H1.83117C1.72358 3.27273 1.63636 3.15062 1.63636 3C1.63636 2.84938 1.72358 2.72727 1.83117 2.72727H2.72727V1.83117C2.72727 1.72358 2.84938 1.63636 3 1.63636Z" fill="#263238" />
                            </svg>
                            <p>Add Task</p>
                        </div>
                    </div>
                    

                    { taskList?.holiday === null  ?  
                    <div className={taskList?.leave !== null ? 'leaveStatusDiv' : 'innerDivTask'} onClick={() => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        setDayView(!dayView);
                        dispatch(setView('day'))
                        window.sessionStorage.setItem("view", 'day')
                    }}>

                    {   taskList.leave !== null ? 
                        <div  className='taslLeaveStatuDiv' style={{ backgroundColor:  taskList.leave.leaveFormat === 'FULLDAY'  ? '#fff' : '#E8E2F3' }} >
                            <div className='leaveStatusDisc' style={{ backgroundColor:  taskList.leave.leaveFormat === 'FULLDAY'  ? '#EA1616' : '#623FAD' }}></div>
                            <div style={{ color: taskList.leave?.leaveFormat === 'FULLDAY' ? '#EA1616' : '#263238'}}>{covertStringFormat(taskList.leave.leaveFormat)}</div>
                        </div>
                        : 
                        <div className='innerDivTaskFormyTaskLeasr' >
                            {taskList.task && taskList.task.length > 0 && taskList.task.slice(0, 3).map((task) => (
                                <div key={task.id} className='taskDiv'>
                                    <div className='taskDiv-half-container'>
                                        <div title={task.status} className="status" style={{ backgroundColor: task.statusColorCode }}></div>
                                        <div className='task-holder'>
                                            <p style={task.status === 'Completed' || task.status === "Submitted" ? { 'text-decoration': 'line-through' } : {}} title={task.taskTitle}>{task.taskTitle}</p>
                                        </div>
                                    </div>
                                    <div className='priority-container'>
                                        <div className='priority' title={task.priority} style={{ backgroundColor: task.priorityColorCode }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }  
                        
                    </div >
                    : <div className='innerDivTaskFormyTask' onClick={() => {
                        if (isDateLessThanCurrent(date)) {
                            toast.warning("No task available before employee's joining date");
                            return
                        }
                        const previousDate = moment(date.value)
                        dispatch(setFullDate(previousDate.format('dddd') + ", " + previousDate.date() + " " + previousDate.format('MMM') + " " + previousDate.year()))
                        setDayView(!dayView);
                        dispatch(setView('day'))
                        window.sessionStorage.setItem("view", 'day')
                    }}>
                         <div className='taskDivCount holidayStatusDay' style={{ background: "#4B555A", width:'80%', height: 'auto'}} onMouseEnter={() => {}}>
                            <div className='taskDiv-half-container'>
                                <div className='task-holder' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div title={`${taskList?.holiday?.holidayDescription}`}>{taskList?.holiday?.holidayTitle.length > 15 ? taskList?.holiday?.holidayTitle.substring(0, 25) + '...' : taskList?.holiday?.holidayTitle}</div>
                                    {/* <svg width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                        // dispatch(setView('month'))
                                    }}>
                                        <circle cx="1.4" cy="10.6" r="1.4" transform="rotate(-90 1.4 10.6)" fill="white" />
                                        <circle cx="1.4" cy="6.00039" r="1.4" transform="rotate(-90 1.4 6.00039)" fill="white" />
                                        <circle cx="1.4" cy="1.40078" r="1.4" transform="rotate(-90 1.4 1.40078)" fill="white" />
                                    </svg> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {taskList?.task && taskList?.task?.length > 3 ?
                        <div className='innerDiv-moreTask'>+{taskList?.task?.length - 3} More {taskList?.task?.length - 3 > 1 ? "Tasks" : "Task"}</div> : <div></div>
                    }
                </div>
            </div >
        );
    };

    const handleDayView = () => {

        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Get the day of the week, date, month, and year
        const dayOfWeek = weekdays[new Date().getDay()];
        const day = new Date().getDate();
        const month = months[new Date().getMonth()];
        const year = new Date().getFullYear();

        // Format the date string
        const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;
        setDayView(!dayView);
        dispatch(setFullDate(formattedDate))
        dispatch(setView('day'))
        window.sessionStorage.setItem("view", 'day')
    };

    const handleWeekData = async (date) => {
        const dateString = date.toString();
        const dateSplit = dateString.split(" ");
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek(),
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
            }
        } catch (error) {
        }
    }

    const onViewChange = (onView, view, date) => {

        dispatch(setView(view))
        window.sessionStorage.setItem("view", view)
        onView(view)
    }

    const onNavigatePrevMonthWeek = async (onNavigate, date) => {
        onNavigate("NEXT", moment(date).subtract(1, 'months').toDate())
        const dateString = moment(date).subtract(1, 'months').toDate().toString();
        const dateSplit = dateString.split(" ");
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek() + 1,
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
            }
        } catch (error) {
        }
    }
    const handleDateRouteWeek = async (e, onNavigate) => {
        onNavigate('DATE', e.target.value)
        const dateString = moment(e.target.value).toString();
        const dateSplit = dateString.split(" ");
        setRouteDate(e.target.value)
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(e.target.value).isoWeek(),
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
            }
        } catch (error) {
        }

    }
    const onNavigateNextMonthWeek = async (onNavigate, date) => {
        onNavigate("PREV", moment(date).add(1, 'months').toDate())
        const dateString = moment(date).add(1, 'months').toDate().toString();
        const dateSplit = dateString.split(" ");
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek() - 1,
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
            }
        } catch (error) {
        }

    }
    const onPrevWeek = async (onNavigate, date) => {
        onNavigate("PREV")
        const dateString = date.toString();
        const dateSplit = dateString.split(" ");
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek() - 1,
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
            }
        } catch (error) {
        }
    }
    const onNextWeek = async (onNavigate, date) => {
        onNavigate("NEXT")
        const dateString = date.toString();
        const dateSplit = dateString.split(" ");
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek() + 1,
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
            }
        } catch (error) {
        }
    }
    const [routeDate, setRouteDate] = useState(new Date())
    const handleDateRoute = (e, onNavigate) => {
        setRouteDate(e.target.value)
        setLoader(true)
        onNavigate('DATE', e.target.value)
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        nDate = moment(e.target.value)
        const month = moment(e.target.value).month() + 1;
        const year = moment(e.target.value).year();
        setIsDateRoute(false)
        if (nDate) {
            if (empView) {
                axios.get(`${url}task/employee/${selfInfo.userId}/get/by/month?year=${year}&month=${month}`, {
                    headers: storedHeaders,
                })
                    .then((response) => {
                        setLoader(false)
                        dispatch(setSearchedMonth([]))
                        dispatch(setMonthData(response.data))

                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 500) {
                            navigate("/server-error")
                        }
                    })
                return
            }
            let data = {
                year: year,
                month: month,
                empId: parseInt(selfInfo.empId)
            };
            axios.post(`${url}task/get/by/month`, data, {
                headers: storedHeaders,
            })
                .then(response => {
                    setLoader(false)
                    dispatch(setSearchedMonth([]))
                    dispatch(setMonthData(response.data))
                })
                .catch(error => {
                })
        }
    }
    const onTodayWeek = async (onNavigate, date) => {
        onNavigate('TODAY')
        const dateString = new Date().toString();
        const dateSplit = dateString.split(" ");
        try {  // Make the HTTP request
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            const reqBody = {
                year: parseInt(dateSplit[3]),
                month: 1,
                weekNumber: moment(dateString).isoWeek(),
                empId: parseInt(selfInfo.empId)
            };
            const response = await axios.post(`${url}task/get/by/week`, reqBody, {
                headers: storedHeaders,
            });
            if (response && response.data) {
                dispatch(setWeekData(response.data))
                setIsDateRoute(false)
            }
        } catch (error) {
        }
    }
    let labelData = [];
    const CustomMonthCell = React.memo(renderCustomMonthCell);

    const CustomToolbar = (toolbar) => {
        const { label, onView, onNavigate, view, date } = toolbar;
        const month = label.split(' ');
        labelData = month;
        const dateString = toolbar.date + "";
        const dateSplit = dateString.split(" ");
        nDate = dateSplit
        const calendarDay = dateSplit[2].toString();
        const newDate = new Date();
        const listDate = newDate.toString().split(" ");
        const getToday = listDate[2].toString();
        const currentMonth = dateSplit[1].toString() + dateSplit[3].toString();
        const todayMonth = listDate[1].toString() + listDate[3].toString();
        const isToday = calendarDay === getToday && currentMonth === todayMonth;
        const weekNumberInMonth = moment(toolbar.date).isoWeek() - moment(toolbar.date).startOf('month').isoWeek() + 1;

        if (viewM === 'week') {
            onView("week")
            dispatch(setView('week'))
        }

        if (viewM == "month") {
            return (
                <div className='monthView-toolbar'>
                    
                    <div className='label'>
                        <svg onClick={() => navigatePrevMonth(onNavigate)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
                        </svg>
                        &nbsp;
                        <div className='label-container' onClick={() => setIsDateRoute(true)}>{month[1]}, {month[0]}</div>
                        &nbsp;
                        <svg onClick={() => navigateNextMonth(onNavigate)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                        </svg>
                        <div className='date-holder-dashboard month-dateRoute'>
                            {
                                isDateRoute ? <div className='calender-holder-dashboard' ref={mothDateRouteWrapper}>
                                    <RouteCalender value={routeDate} inline view='month' unstyled={false} onChange={(e) => handleDateRoute(e, onNavigate)} />
                                </div> : null
                            }

                        </div>
                    </div>
                    <div className='viewBtns'>
                        <button className='todayView-btn' style={{ background: isToday ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={() => onNavigateToday(onNavigate)}>Today</button>
                        <button className='monthView-btn monthView-btn-view' style={{ background: view === "month" ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={() => {
                            onViewChange(onView, 'month', date)
                        }}>Month</button>
                        <button className='weekView-btn monthView-btn-view' style={{ background: view === "week" ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={() => onViewChange(onView, 'week', date)}>Week</button>
                        <button className="dayView-btn monthView-btn-view" style={{ background: view === "day" ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={handleDayView}>Day</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='monthView-toolbar'>
                    <div className='labels-container'>
                        <div className='label'>
                            <svg onClick={() => onNavigatePrevMonthWeek(onNavigate, date)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
                            </svg>
                            &nbsp;
                            <div className='label-container' onClick={() => setIsDateRoute(true)}>{moment(toolbar.date).format('MMMM')}, {dateSplit[3]}</div>
                            &nbsp;
                            <svg onClick={() => onNavigateNextMonthWeek(onNavigate, date)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                            </svg>
                            {/* <ShortCalender sendDataToParent={handleChildData} currentDate={currentDate} /> */}
                            <div className='date-holder-dashboard month-dateRoute'>
                                {
                                    isDateRoute ? <div className='calender-holder-dashboard' ref={mothDateRouteWrapper}>
                                        <RouteCalender value={routeDate} inline view='month' unstyled={false} onChange={(e) => handleDateRouteWeek(e, onNavigate)} />
                                    </div> : null
                                }

                            </div>
                        </div>
                        <div className='weekLabel'>
                            <svg onClick={() => onPrevWeek(onNavigate, date)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
                            </svg>
                            &nbsp;
                            <div className='weekLabel-container'>Week {weekNumberInMonth}</div>
                            &nbsp;
                            <svg onClick={() => onNextWeek(onNavigate, date)} width="10" height="15" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
                            </svg>
                        </div>
                    </div>
                    <div className='viewBtns'>
                        <button className='todayView-btn' style={{ background: isToday ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={() => onTodayWeek(onNavigate, date)}>Today</button>
                        <button className='monthView-btn monthView-btn-view' style={{ background: view === "month" ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={() => {
                            onViewChange(onView, 'month', date)
                        }
                            }>Month</button>
                        <button className='weekView-btn monthView-btn-view' style={{ background: view === "week" ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={() => onViewChange(onView, 'week', date)}>Week</button>
                        <button className="dayView-btn monthView-btn-view" style={{ background: view === "day" ? '#FFF6DF' : 'none', cursor: 'pointer'}} onClick={handleDayView} >Day</button>
                    </div>
                </div>
            );
        }
    }

    const CostomWeekcell = (e) => {
        return <WeekView props={e} />
    }

    return (
        <div className='views'>
            {viewM !== 'day' ?
                <Calendar
                    localizer={localizer}
                    events={[]}
                    startAccessor="start"
                    endAccessor="end"
                    cultures={{ 'en-US': culture }}
                    components={{
                        toolbar: CustomToolbar,
                        month: {
                            dateCellWrapper: CustomMonthCell,
                        },
                        week: {
                            header: CostomWeekcell,
                        },
                    }}
                /> : <BasicTable />
            }
        </div>
    );
};

export default CalenderView;
