import React, { useEffect, useRef, useState } from 'react'
import './Request.css'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setResetObject, setDenyRqtObj, setPreAskOption, setReqObj, setRequestRejectI, setrqtDenypopup, setRequestCount, setUnRdNotiCount, setReqCountData } from '../../../erpStore/actions';
import profile from "../../../assets/images/profile.png"
import noData from "../../../assets/images/ClosureData.png";
import { debounce } from "lodash";
import { useNavigate } from 'react-router-dom';
import RequestCountSection from './RequestCountSection';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
function Request() {

    const dispatch = useDispatch()
    const stringHeaders = window.localStorage.getItem('myHeaders');
    const storedHeaders = JSON.parse(stringHeaders);
    const self = useSelector((state) => state.data.selfData)
    const url = useSelector((state) => state.data.apiUrl)
    const [count, setCount] = useState(0)
    const [requestType, setRequestType] = useState("empReq")
    const [reqStatus, setReqStatus] = useState("Pending")
    const [isRefRotate, setIsRefRotate] = useState(false)
    const [loaderRequest, setLoaderRequest] = useState(false);
    const requestObj = useSelector((state) => state.data.reqObj)
    const [page, setPage] = useState(0);
    const [loader, setLoader] = useState();
    const [isLastPage, setIslastPage] = useState(false);
    const reqLength = 10;
    const [search, setSearch] = useState("")
    const navigate = useNavigate();
    const [searchEmp, setSearchEmp] = useState('')
    const [reqType, setReqType] = useState('all');
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const reqObj = useSelector((state) => state.data.reqObj)
    const [reqListLength, setReqListLenght] = useState(0)
    const [seearchKey, setSearchKey] = useState('')
    const [testerListFlag, setTesterListFlag] = useState(false)
    const [requestId, setRequestId] = useState(null)
    const testerListRef = useRef(null)
    const testerpopRef = useRef(null)
    const [testerPopUp, setTesetrPopup] = useState(false)
    const [selectedQA, setSelectedQA] = useState(null)
    const [sendLoader, setSendLoader] = useState(false)
    const [approveLoader, setAppLoader] = useState(false) 

    const getAllPendingRqt = () => {
        if(requestType === "empReq"){
            axios.get(`${url}task/req/get/all?page=0&size=${reqLength}&status=${reqStatus}`, { headers: storedHeaders })
            .then(response => {
                dispatch(setResetObject(response.data.data))
                dispatch(setReqObj(response.data.data));
                setIsRefRotate(false)
                setLoaderRequest(false)
                setIslastPage(response.data.isLastPage)
                setPage(0)
            })
            .catch((error) => { setLoaderRequest(false) });
        }
    }

    useEffect(() => {
        if (reqObj?.length === 1 && seearchKey === '') {
            getAllPendingRqt()
        }

    }, [reqObj?.length])

    useEffect(() => {
        dispatch(setReqObj(requestObj))
    }, [requestObj])

    useEffect(() => {
        setLoaderRequest(true)
        if (requestType === "empReq") {
            axios.get(`${url}task/req/get/all?page=0&size=${reqLength}&status=${reqStatus}`, { headers: storedHeaders })
                .then(response => {
                    setIsRefRotate(false)
                    setLoaderRequest(false)
                    setIslastPage(response.data.isLastPage)
                    dispatch(setResetObject(response.data.data))
                    dispatch(setReqObj(response.data.data));
                    const tempData = response.data.data
                    tempData.map((obj, index) => {
                        if(obj.qaList.length > 0){
                           tempData[index]['selectedTester'] = null 
                        }
                    })
                    dispatch(setResetObject(tempData))
                    dispatch(setReqObj(tempData));
                    setPage(0)
                })
                .catch((error) => { setLoaderRequest(false) });
        } else {
            axios.post(`${url}task/req/my/all`, {
                userId: self.userId,
                status: reqStatus
            }, { headers: storedHeaders })
                .then(response => {
                    dispatch(setResetObject(response.data.data))
                    dispatch(setReqObj(response.data.data));
                    setLoaderRequest(false)
                    setIsRefRotate(false)
                    setIslastPage(response.data.isLastPage)
                    if(response.data.isLastPage === false){
                        setPage(page + 1)
                    }
                
                })
                .catch((error) => { setLoaderRequest(false) });
        }
        const fetchCount = async () => {
            try {
                const response = await axios.get(`${url}notification/logs/get/unread-count?userId=${self.userId}`, {
                    headers: storedHeaders
                })
                if (response && response.data) {
                    dispatch(setUnRdNotiCount(response.data.notificationUnreadCount))
                }
            } catch (err) {

                if (err.response && err.response.status === 500) {
                    navigate("/server-error")
                }

            } 
            try {
                const response = await axios.get(`${url}task/pending-req`, {
                    headers: storedHeaders
                })
                if (response && response.data) {
                    dispatch(setRequestCount(response.data))
                } else {
                    if (response.data === 0) {
                        dispatch(setRequestCount(response.data))
                    }
                }

            } catch (err) {

                if (err.response && err.response.status === 500) {
                    navigate("/server-error")
                }
            }
        }
        if (self.userId) {
            fetchCount();
        }

    }, [count])

    function setMyRequestView(e) {
        setPage(0)
        if (e === requestType) {
            return
        }
        setLoaderRequest(true)
        setRequestType(e)
        setReqStatus("Pending")
        if (e === "myRequests") {
            axios.post(`${url}task/req/my/all?page=${page}&size=${reqLength}`, {
                userId: self.userId,
                status: "Pending",
            }, { headers: storedHeaders })
                .then(response => {
                    if (response.request.status === 200) {
                        dispatch(setResetObject(response.data.data))
                        dispatch(setReqObj(response.data.data));
                        setLoaderRequest(false)
                        setIsRefRotate(false)
                        setIslastPage(response?.data?.isLastPage)
                        if(response?.data?.isLastPage === false){
                            setPage(page + 1)
                        }
                    }
                })
                .catch((error) => {
                    setLoaderRequest(false)
                });
        } else {
            axios.get(`${url}task/req/get/all?page=${0}&size=${reqLength}&status=Pending`, { headers: storedHeaders })
                .then(response => {
                    dispatch(setResetObject(response.data.data))
                    dispatch(setReqObj());
                    setLoaderRequest(false)
                    dispatch(setReqObj(response.data.data));
                    setIslastPage(response.data.isLastPage)
                })
                .catch((error) => { setLoaderRequest(false) });
        }

    }

    const timeCalculator = (lastUpdated) => {
        const parsedDate = new Date(lastUpdated);
        const formattedDate = `${parsedDate.getUTCFullYear()}-${(parsedDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getUTCDate().toString().padStart(2, '0')}T${parsedDate.getUTCHours().toString().padStart(2, '0')}:${parsedDate.getUTCMinutes().toString().padStart(2, '0')}:${parsedDate.getUTCSeconds().toString().padStart(2, '0')}Z`;
        const currentDateTime = new Date();
        const lastUpdatedDateTime = new Date(formattedDate);
        const timeDifference = (currentDateTime - lastUpdatedDateTime) / 1000;
        const secondsInMinute = 60;
        const secondsInHour = 3600;
        const secondsInDay = 86400;
        const secondsInWeek = 604800;
        const secondsInMonth = 2629800;
        const secondsInYear = 31557600;

        if (timeDifference >= secondsInYear) {
            const yearsAgo = Math.floor(timeDifference / secondsInYear);
            return `${yearsAgo} ${yearsAgo === 1 ? 'yr' : 'yrs'} ago`;
        } else if (timeDifference >= secondsInMonth) {
            const monthsAgo = Math.floor(timeDifference / secondsInMonth);
            return `${monthsAgo} ${monthsAgo === 1 ? 'mon' : 'mons'} ago`;
        } else if (timeDifference >= secondsInWeek) {
            const weeksAgo = Math.floor(timeDifference / secondsInWeek);
            return `${weeksAgo} ${weeksAgo === 1 ? 'wk' : 'wks'} ago`;
        } else if (timeDifference >= secondsInDay) {
            const daysAgo = Math.floor(timeDifference / secondsInDay);
            return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
        } else if (timeDifference >= secondsInHour) {
            const hoursAgo = Math.floor(timeDifference / secondsInHour);
            return `${hoursAgo} ${hoursAgo === 1 ? 'hr' : 'hrs'} ago`;
        } else {
            const minutesAgo = Math.floor(timeDifference / secondsInMinute);
            return `${minutesAgo} ${minutesAgo === 1 ? 'min' : 'mins'} ago`;
        }
    };
    const handleSearchRequestCount = async (e) => {
        setSearchEmp(e)
        const currentDate = moment(date);
        setDate(currentDate)
        setLoaderRequest(true)

        setDisplayedDate(currentDate.format('dddd') + ", " + currentDate.date().toString().padStart(2, "0") + " " + currentDate.format('MMM') + " " + currentDate.year())
        // const formattedDate = formatDate(date)
        try {
            const response = await axios.post(`${url}task/req-overview`, {
                userId: self.userId,
                key: e
            }, { headers: storedHeaders })
            if (response && response.data) {
                dispatch(setReqCountData(response.data))
                setLoaderRequest(false)
            }
        }
        catch (err) {
            
            setLoaderRequest(false)
        }
    }
    const handleDebounceRequestCount = React.useCallback(debounce(handleSearchRequestCount ,2000), []);
    const dateRouteRef = useRef(null)
    const [reqCountSec, setReqCountSection] = useState(false)
    const [isDateRoute, setIsDateRoute] = useState(false)
    const [date, setDate] = useState()
    const [displayedDate, setDisplayedDate] = useState("")
    function getMonthByNumber(monthNumber) {
        var date = new Date(2000, monthNumber - 1, 1);
        var monthName = date.toLocaleString('default', { month: 'long' });

        return monthName;
    }
    const handlePrevDate = async () => {
        const currentDate = moment(date);
        const previousDate = currentDate.subtract(1, 'day');
        setDate(currentDate)
        setLoaderRequest(true)
        setDisplayedDate(previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year())
        const formattedDate = formatDate(previousDate)
        try {
            setSearchEmp('')
            const response = await axios.post(`${url}task/req-overview`, {
                userId: self.userId,
                dateStart: formattedDate,
                dateEnd: formattedDate,
                key: searchEmp
            }, { headers: storedHeaders })
            if (response && response.data) {
                dispatch(setReqCountData(response.data))
                setIsDateRoute(false)
                setLoaderRequest(false)
            }
        }
        catch (err) {
            setLoaderRequest(false)
            
        }
    }
    const handleNextDate = async () => {
        const currentDate = moment(date);
        const nextDate = currentDate.add(1, 'day')
        setDate(currentDate)
        setLoaderRequest(true)
        setDisplayedDate(nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year())
        const formattedDate = formatDate(nextDate)
        try {
            const response = await axios.post(`${url}task/req-overview`, {
                userId: self.userId,
                dateStart: formattedDate,
                dateEnd: formattedDate,
                key: searchEmp
            }, { headers: storedHeaders })
            if (response && response.data) {
                dispatch(setReqCountData(response.data))
                setIsDateRoute(false)
                setLoaderRequest(true)

            }
        }
        catch (err) {
            setLoaderRequest(true)
            
        }
    }
    useEffect(() => {
        const date = new Date()
        const tempDate = new moment()
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        setDate(date)
        setDisplayedDate(`${tempDate.format('dddd')}, ${day} ${getMonthByNumber(parseInt(month))} ${year}`)

    }, [])

    useEffect(() => {
        function handleOutsideClick(event) {
            if (dateRouteRef && dateRouteRef.current && !dateRouteRef.current.contains(event.target)) {
                setIsDateRoute(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])
    function formatDate(date) {
        const dateNew = new Date(date)
        const year = dateNew.getFullYear();
        const month = String(dateNew.getMonth() + 1).padStart(2, '0');
        const day = String(dateNew.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const handleReqCountDateRoute = async (e) => {
        const currentDate = moment(e.value);
        setDate(currentDate)
        setDisplayedDate(currentDate.format('dddd') + ", " + currentDate.date().toString().padStart(2, "0") + " " + currentDate.format('MMM') + " " + currentDate.year())
        const formattedDate = formatDate(e.value)
        setLoaderRequest(true)
        try {
            const response = await axios.post(`${url}task/req-overview`, {
                userId: self.userId,
                dateStart: formattedDate,
                dateEnd: formattedDate,
                key: searchEmp
            }, { headers: storedHeaders })
            if (response && response.data) {
                dispatch(setReqCountData(response.data))
                setIsDateRoute(false)
                setLoaderRequest(false)
            }
        }
        catch (err) {
            setLoaderRequest(false)
            
        }
    }
    const handleOverView = async () => {
        setLoaderRequest(true)
        const currentDate = moment(new Date());
        setReqType('all')
        setDate(currentDate)
        setStartDate('')
        setEndDate('')
        setDisplayedDate(currentDate.format('dddd') + ", " + currentDate.date().toString().padStart(2, "0") + " " + currentDate.format('MMM') + " " + currentDate.year())
        const dateformat = formatDate(currentDate)
        setLoaderRequest(true)
        try {
            const response = await axios.post(`${url}task/req-overview`, {
                userId: self.userId,
                // dateStart: dateformat,
                // dateEnd: dateformat,
                key: ''
            }, { headers: storedHeaders })
            if (response && response.data) {
                dispatch(setReqCountData(response.data))
                setLoaderRequest(false)
                setSearchKey('')
            }
        }
        catch (err) {
            setLoaderRequest(false)
        }
    }

    useEffect(() => {
        function handleOutsideClick(event) {
          if (testerListRef.current && !testerListRef.current.contains(event.target)) {
            if(testerListRef === true){
                setTesetrPopup(false)
            }
            setTesterListFlag(false)
          }else if(testerpopRef && testerpopRef.current && !testerpopRef.current.contains(event.target)){
            setTesterListFlag(false)
            setTesetrPopup(false)
            setSelectedQA(null)
          }
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick)
        }
      }, [])


    const handleRangeOverview = async (start, end) => {
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        const startDate = moment(start).format('YYYY-MM-DD')
        const endDate = moment(end).format('YYYY-MM-DD')
        setReqType('range')
        setLoaderRequest(true)
        try {
            const response = await axios.post(`${url}task/req-overview`, {
                userId: self.userId,
                dateStart: startDate,
                dateEnd: endDate === "Invalid date" ? startDate : endDate,
                key: searchEmp
            }, { headers: storedHeaders })
            if (response && response.data) {
                dispatch(setReqCountData(response.data))
                setLoaderRequest(false)
            }
        }
        catch (err) {
            
            setLoaderRequest(false)
        }
    }

    async function handleSearch(searchTerm, reqStatus){
        if (searchTerm === '') {
            axios.get(`${url}task/req/get/all?page=${0}&size=${reqLength}&status=${reqStatus}`, { headers: storedHeaders })
                .then(response => {
                    if (response.request.status === 200) {
                        dispatch(setReqObj(response.data.data))
                        setIsRefRotate(false)
                        setLoaderRequest(false)
                        setPage(0)
                        setIslastPage(response.data.isLastPage)
                    }
                })
                .catch((error) => { setLoaderRequest(false) });
        } else {
            if(searchTerm.length >= 2){
            setLoaderRequest(true)
            const data = await axios.get(`${url}task/search/request?key=${searchTerm}&userId=${self.userId}&status=${reqStatus}`, { headers: storedHeaders })
                .then(response => {
                    setLoaderRequest(false)
                    setIslastPage(true)
                    dispatch(setReqObj(response.data));
                })
                .catch((error) => { setLoaderRequest(false) });
            }
        }
    }

    const handleSearchReuquestDebounce = React.useCallback(debounce(handleSearch, 500), [])

    return (
        <div className='request-main-container'>
            <div className='request-header'>
                <div className='req-header-container'>
                    <div className='retTitle' onClick={() => {
                        setMyRequestView("empReq")
                        setSearchKey('')
                        setReqCountSection(false)
                        setLoaderRequest(true)
                        axios.get(`${url}task/req/get/all?page=0&size=${reqLength}&status=${reqStatus}`, { headers: storedHeaders })
                        .then(response => {
                            setIsRefRotate(false)
                            setLoaderRequest(false)
                            setIslastPage(response.data.isLastPage)
                            dispatch(setResetObject(response.data.data))
                            dispatch(setReqObj(response.data.data));
                            const tempData = response.data.data
                            tempData.map((obj, index) => {
                                if(obj.qaList.length > 0){
                                   tempData[index]['selectedTester'] = null 
                                }
                            })
                            dispatch(setResetObject(tempData))
                            dispatch(setReqObj(tempData));
                            setPage(0)
                        })
                        .catch((error) => { 
                            setLoaderRequest(false)
                            setLoaderRequest(false)

                        });

                        }} style={{ borderBottom: !reqCountSec ? "3px solid #FF8402" : null, color: !reqCountSec ? "rgb(0 0 0)" : null, fontSize: !reqCountSec ? null : "17px", marginRight: "10px" }}>
                        Request
                    </div>
                    <div className='retTitle' onClick={() => {
                        setReqCountSection(true)
                        setSearchEmp('')
                        handleOverView()
                        setReqStatus('Pending')
                    }} style={{ borderBottom: reqCountSec ? "3px solid #FF8402" : null, color: reqCountSec ? "rgb(0 0 0)" : null, fontSize: reqCountSec ? null : "17px" }}>
                        Overview
                    </div>
                    
                    {
                        reqCountSec ? <div className={`analytics-date-range-container ${reqType}`}>
                            <DatePicker
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    const [start, end] = update;
                                    setStartDate(start);
                                    setEndDate(end);
                                    handleRangeOverview(start, end)
                                }}
                                placeholderText='Start Date - End Date'
                                startDatePlaceholderText="Start Date"
                                endDatePlaceholderText="End Date"
                                dateFormat="MM/dd/yyyy"
                                withPortal
                            />
                        </div> : null
                    }

                    <svg title="Refresh" className={isRefRotate ? 'rotateRqtRef' : ''} onClick={() => {
                        setIsRefRotate(true)
                        setCount(count + 1)
                        setSearchKey('')
                        axios.get(`${url}task/pending-req`, { headers: storedHeaders })
                            .then(response => {
                                if (response.status.request === 200) {
                                    dispatch(setRequestCount(response.data))
                                }
                            })
                            .catch(error => {
                            })


                    }} style={{ marginLeft: "20px" }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4V10H7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23 20V14H17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.49 8.99959C19.9828 7.56637 19.1209 6.28499 17.9845 5.27501C16.8482 4.26502 15.4745 3.55935 13.9917 3.22385C12.5089 2.88834 10.9652 2.93393 9.50481 3.35636C8.04437 3.77879 6.71475 4.5643 5.64 5.63959L1 9.99959M23 13.9996L18.36 18.3596C17.2853 19.4349 15.9556 20.2204 14.4952 20.6428C13.0348 21.0652 11.4911 21.1108 10.0083 20.7753C8.52547 20.4398 7.1518 19.7342 6.01547 18.7242C4.87913 17.7142 4.01717 16.4328 3.51 14.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                {reqCountSec ? <div className='search-for-count'> <div className='searchRequest' style={{ width: "250px" }}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.77005 0.341571C3.31446 0.116066 3.89796 0 4.48724 0C5.07651 0 5.66001 0.116066 6.20443 0.341571C6.74885 0.567075 7.24351 0.897603 7.66019 1.31428C8.07687 1.73096 8.4074 2.22563 8.63291 2.77005C8.85841 3.31446 8.97447 3.89797 8.97447 4.48724C8.97447 5.07651 8.85841 5.66001 8.6329 6.20443C8.45661 6.63004 8.21613 7.02525 7.92048 7.37656L9.88735 9.34343C10.0375 9.49363 10.0375 9.73715 9.88735 9.88735C9.73715 10.0375 9.49363 10.0375 9.34343 9.88735L7.37656 7.92049C6.5709 8.59857 5.54845 8.97447 4.48724 8.97447C3.29715 8.97447 2.1558 8.50171 1.31428 7.66019C0.472761 6.81867 0 5.67733 0 4.48724C0 3.29715 0.472761 2.1558 1.31428 1.31428C1.73096 0.897603 2.22563 0.567075 2.77005 0.341571ZM4.48724 0.769223C3.99898 0.769223 3.5155 0.865392 3.06441 1.05224C2.61332 1.23909 2.20345 1.51295 1.8582 1.8582C1.16094 2.55547 0.769223 3.50116 0.769223 4.48724C0.769223 5.47332 1.16094 6.41901 1.8582 7.11627C2.55547 7.81353 3.50116 8.20525 4.48724 8.20525C5.47332 8.20525 6.41901 7.81353 7.11627 7.11627C7.46152 6.77102 7.73539 6.36115 7.92224 5.91006C8.10908 5.45897 8.20525 4.97549 8.20525 4.48724C8.20525 3.99898 8.10908 3.5155 7.92224 3.06441C7.73539 2.61332 7.46152 2.20345 7.11627 1.8582C6.77102 1.51295 6.36115 1.23909 5.91006 1.05224C5.45897 0.865392 4.97549 0.769223 4.48724 0.769223Z" fill="#263238" />
                    </svg> <input type="search" value={searchEmp} placeholder="Search employee name" onChange={(e) => {
                        handleDebounceRequestCount(e.target.value)
                        setSearchEmp(e.target.value)
                        }} />
                </div>
                    <div className='all-request-count' style={{ background: reqType === 'all' ? '#faebd7' : null }} onClick={() => {
                        handleOverView()
                        setSearchEmp('')
                        }}>All</div>
                </div> : <div className='searchRequestDiv'>
                    {
                        requestType !== "myRequests" ?
                            <div className='searchRequest'>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.77005 0.341571C3.31446 0.116066 3.89796 0 4.48724 0C5.07651 0 5.66001 0.116066 6.20443 0.341571C6.74885 0.567075 7.24351 0.897603 7.66019 1.31428C8.07687 1.73096 8.4074 2.22563 8.63291 2.77005C8.85841 3.31446 8.97447 3.89797 8.97447 4.48724C8.97447 5.07651 8.85841 5.66001 8.6329 6.20443C8.45661 6.63004 8.21613 7.02525 7.92048 7.37656L9.88735 9.34343C10.0375 9.49363 10.0375 9.73715 9.88735 9.88735C9.73715 10.0375 9.49363 10.0375 9.34343 9.88735L7.37656 7.92049C6.5709 8.59857 5.54845 8.97447 4.48724 8.97447C3.29715 8.97447 2.1558 8.50171 1.31428 7.66019C0.472761 6.81867 0 5.67733 0 4.48724C0 3.29715 0.472761 2.1558 1.31428 1.31428C1.73096 0.897603 2.22563 0.567075 2.77005 0.341571ZM4.48724 0.769223C3.99898 0.769223 3.5155 0.865392 3.06441 1.05224C2.61332 1.23909 2.20345 1.51295 1.8582 1.8582C1.16094 2.55547 0.769223 3.50116 0.769223 4.48724C0.769223 5.47332 1.16094 6.41901 1.8582 7.11627C2.55547 7.81353 3.50116 8.20525 4.48724 8.20525C5.47332 8.20525 6.41901 7.81353 7.11627 7.11627C7.46152 6.77102 7.73539 6.36115 7.92224 5.91006C8.10908 5.45897 8.20525 4.97549 8.20525 4.48724C8.20525 3.99898 8.10908 3.5155 7.92224 3.06441C7.73539 2.61332 7.46152 2.20345 7.11627 1.8582C6.77102 1.51295 6.36115 1.23909 5.91006 1.05224C5.45897 0.865392 4.97549 0.769223 4.48724 0.769223Z" fill="#263238" />
                                </svg> <input type="search"  value={seearchKey}  placeholder="Search employee name" onChange={(e) => {
                                    setSearchKey(e.target.value)
                                    handleSearchReuquestDebounce(e.target.value, reqStatus, 2000);
                                    }} />
                            </div> : null
                    }

                    <button onClick={() => { {
                        setMyRequestView("empReq")} }} style={{ background: requestType === "empReq" ? "rgba(255, 246, 223, 1)" : null, marginRight: "0px" }} className='my-req'>Requests</button>
                    {self && self.role && self.role[0].roleLevel !== 1 ?
                        <button onClick={() => {
                            setSearchKey('')
                            setMyRequestView("myRequests")}} style={{ background: requestType === "myRequests" ? "rgba(255, 246, 223, 1)" : null }} className='my-req'>My Requests</button> : null}
                    {requestType === "empReq" ?
                        <select className='filtersForRequests' value={reqStatus} onChange={(event) => {
                            setReqStatus(event.target.value)
                            setLoaderRequest(true)
                            const selectedValue = event.target.value;
                            const stringHeaders = window.localStorage.getItem('myHeaders');
                            const storedHeaders = JSON.parse(stringHeaders);
                            if (selectedValue === "Pending") {
                                setReqStatus('Pending')
                                setSearchKey('')
                                axios.get(`${url}task/req/get/all?page=${0}&size=${reqLength}&status=Pending`, { headers: storedHeaders })
                                    .then(response => {
                                        if (response.request.status === 200) {
                                            dispatch(setReqObj(response.data.data))
                                            setIsRefRotate(false)
                                            setLoaderRequest(false)
                                            setPage(0)
                                            setIslastPage(response.data.isLastPage)
                                        }
                                    })
                                    .catch((error) => { setLoaderRequest(false) });
                            } else if (selectedValue === "Accepted") {
                                setReqStatus('Accepted')
                                setSearchKey('')
                                axios.get(`${url}task/req/get/all?page=${0}&size=${reqLength}&status=Accepted`, { headers: storedHeaders })
                                    .then(response => {
                                        if (response.request.status === 200) {
                                            dispatch(setReqObj(response.data.data))
                                            setIsRefRotate(false)
                                            setLoaderRequest(false)
                                            setPage(0)
                                            
                                            setIslastPage(response.data.isLastPage)
                                        }
                                    }).catch((error) => { setLoaderRequest(false) });

                            } else if (selectedValue === "Rejected") {
                                setReqStatus('Rejected')
                                setSearchKey('')
                                axios.get(`${url}task/req/get/all?page=${0}&size=${reqLength}&status=Rejected`, { headers: storedHeaders })
                                    .then(response => {
                                        if (response.request.status === 200) {
                                            dispatch(setReqObj(response.data.data))
                                            setIsRefRotate(false)
                                            setLoaderRequest(false)
                                            setPage(0)
                                            
                                            setIslastPage(response.data.isLastPage)
                                        }

                                    })
                                    .catch((error) => { setLoaderRequest(false) });
                            }
                        }}>
                            <option>Pending</option>
                            <option>Accepted</option>
                            <option>Rejected</option>
                        </select> : <select className='filtersForRequests' value={reqStatus} onChange={(event) => {
                            //myreq
                            setReqStatus(event.target.value)
                            setLoaderRequest(true)
                            const selectedValue = event.target.value;
                            const stringHeaders = window.localStorage.getItem('myHeaders');
                            const storedHeaders = JSON.parse(stringHeaders);
                            if (selectedValue === "Pending") {
                                setReqStatus('Pending')
                                axios.post(`${url}task/req/my/all`, {
                                    userId: self.userId,
                                    status: "Pending"
                                }, { headers: storedHeaders })
                                    .then(response => {
                                        dispatch(setResetObject(response.data.data))
                                        dispatch(setReqObj(response.data.data));
                                        setIsRefRotate(false)
                                        setLoaderRequest(false)
                                        setIslastPage(response?.data?.isLastPage)
                                    })
                                    .catch((error) => {
                                        setIsRefRotate(false)
                                        setLoaderRequest(false)
                                    });
                            } else if (selectedValue === "Accepted") {
                                setReqStatus('Accepted')
                                axios.post(`${url}task/req/my/all`, {
                                    userId: self.userId,
                                    status: "Accepted"
                                }, { headers: storedHeaders })
                                    .then(response => {
                                        dispatch(setResetObject(response.data.data))
                                        dispatch(setReqObj(response.data.data));
                                        setIsRefRotate(false)
                                        setLoaderRequest(false)
                                        setIslastPage(response?.data?.isLastPage)

                                    })
                                    .catch((error) => {
                                        setIsRefRotate(false)
                                        setLoaderRequest(false)
                                    });
                            } else if (selectedValue === "Rejected") {
                                setReqStatus('Rejected')
                                axios.post(`${url}task/req/my/all`, {
                                    userId: self.userId,
                                    status: "Rejected"
                                }, { headers: storedHeaders })
                                    .then(response => {
                                        dispatch(setResetObject(response.data.data))
                                        dispatch(setReqObj(response.data.data));
                                        setIsRefRotate(false)
                                        setLoaderRequest(false)
                                        setIslastPage(response?.data?.isLastPage)

                                    })
                                    .catch((error) => {
                                        setIsRefRotate(false)
                                        setLoaderRequest(false)
                                    });
                            }
                        }}>
                            <option>Pending</option>
                            <option>Accepted</option>
                            <option>Rejected</option>
                        </select>

                    }

                </div>}
            </div>{

            }
            {
                loaderRequest ? <div className='loader-container' style={{ background: "#d9d9d9" }}>

                    <div className='loaderRequest'></div>
                </div> : (reqCountSec ? <RequestCountSection /> : <div className='reuest-sub-container'>

                    {requestObj && requestObj.length > 0 && requestObj.map((reqObjInner, Outerindex) => (
                        <div className='request-card'>
                            <div className='request-sender-image'>
                                <img style={{ borderRadius: '50px' }} src={reqObjInner && reqObjInner?.requestFrom && reqObjInner?.requestFrom?.empImg !== null ? reqObjInner?.requestFrom?.empImg : profile} alt='/'></img>
                            </div>
                            <div className='request-subject-content'>
                                <p style={{ fontSize: '20px', fontWeight: '550' }}>{reqObjInner?.requestFrom?.empFullName} <span style={{ color: '#FF8402', marginTop: '4px', fontWeight: "100", fontSize: "15px" }}>{reqObjInner?.requestFrom?.designation?.designation}</span></p>
                                <div style={{ marginTop: '8px' }} className='request-subject-column'>

                                    <p className='sendTo'> Assigned By: {reqObjInner?.taskAssignedBy} on {reqObjInner?.taskScheduleDate === reqObjInner?.taskDueDate ? `${reqObjInner?.taskScheduleDate}` : `${reqObjInner?.taskScheduleDate} to ${reqObjInner?.taskDueDate}`}</p>
                                    {requestType === "myRequests" && reqStatus === "Pending" ? <p className='sendTo'> Sent To: {reqObjInner.requestSendTo && reqObjInner.requestSendTo.length > 0 ? reqObjInner.requestSendTo.map((employee, index) => index > 0 ? `, ${employee}` : employee) : null}</p> : null}
                                    {reqStatus === "Accepted" ? <p className='sendTo'> Accepted By: {reqObjInner?.requestActionBy}</p> : null}
                                    {reqStatus === "Rejected" ? <p className='sendTo'> Rejected By: {reqObjInner?.requestActionBy}</p> : null}

                                    <p>{reqObjInner?.requestDescription ? reqObjInner?.requestDescription : '   -'}</p>
                                    {reqObjInner?.requestRemark ?
                                        <p>Reason: {reqObjInner?.requestRemark}</p> : null
                                    }
                                    <p style={{ fontWeight: '600' }}>{reqObjInner?.requestName}</p>
                                    <p style={{ color: "#0000008a" }} title={`${reqObjInner?.requestDate}`}>{timeCalculator(`${reqObjInner?.requestDate}`)}</p>
                                </div>
                            </div>
                            {requestType === "empReq" ?
                                <div className='request-approval-btn'>
                                    {reqObjInner?.requestStatusEntity?.requestStatus === 'Pending' &&
                                        <button id="req-approved-btn" className='req-approved-btn' onClick={!loader ? () => {
                                            if(reqObjInner?.qaList?.length > 0){
                                                setTesetrPopup(true)
                                                setRequestId(reqObjInner?.requestId)
                                            }else{
                                            setAppLoader(true)
                                            setTesetrPopup(false)
                                            setRequestId(reqObjInner?.requestId)
                                            const stringHeaders = window.localStorage.getItem('myHeaders');
                                            const storedHeaders = JSON.parse(stringHeaders);
                                            const sendData = {
                                                "requestId" :reqObjInner?.requestId,
                                                 "empId" : self.empId,
                                                 "statusId" : 1
                                            }

                                            if(reqObjInner.hasOwnProperty('selectedTester')){
                                                sendData['testerId'] =  reqObjInner?.selectedTester?.empId  
                                            }
                                            axios.put(`${url}task/req/status/change`, sendData, {
                                                headers: storedHeaders
                                            })
                                                .then(response => {
                                                    if (response.status === 200) {
                                                        setAppLoader(false)
                                                        setIsRefRotate(false)
                                                        setIsRefRotate(false)
                                                        const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqObjInner.requestId);
                                                        dispatch(setReqObj(updatedReqObj))
                                                        setIsRefRotate(false)
                                                        try {
                                                            axios.get(`${url}task/pending-req`, {
                                                                headers: storedHeaders
                                                            })
                                                                .then((response) => {
                                                                    if (response && response.data) {
                                                                        dispatch(setRequestCount(response.data))
                                                                    } else {
                                                                        if (response.data === 0) {
                                                                            dispatch(setRequestCount(response.data))
                                                                        }
                                                                    }
                                                                }).catch((err) => {
                                                                    
                                                                    setAppLoader(false)
                                                                    if (err.response && err.response.status === 500) {
                                                                        navigate("/server-error")
                                                                    }
                                                                })

                                                        } catch (err) {
                                                            setAppLoader(false)
                                                            if (err.response && err.response.status === 500) {
                                                                navigate("/server-error")
                                                            }

                                                        }

                                                    }
                                                })
                                                .catch(error => {
                                                    setIsRefRotate(false)
                                                    setAppLoader(false)
                                                    if(error.request.status ===404){
                                                        const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqObjInner.requestId);
                                                        dispatch(setReqObj(updatedReqObj))
                                                    }
                                                })
                                        }} : null}>{approveLoader && requestId === reqObjInner?.requestId  ? <div className="approvedLoader"></div> : 'Approve'}</button>
                                    }
                                    {reqObjInner?.requestStatusEntity?.requestStatus === 'Pending' &&
                                        <button id="req-deny-btn" onClick={() => {
                                            dispatch(setRequestRejectI(reqObjInner.requestId))
                                            dispatch(setrqtDenypopup(true))
                                            dispatch(setDenyRqtObj(reqObjInner))
                                        }
                                        }
                                        >Reject</button>
                                    }


                                    {reqObjInner?.requestStatusEntity?.requestStatus === 'Accepted' &&
                                        <button style={{ background: 'green', cursor: "auto" }} id="req-approved-btn">Accepted</button>
                                    }

                                    {reqObjInner?.requestStatusEntity?.requestStatus === 'Rejected' &&
                                        <button style={{ background: 'red', cursor: "auto" }} id="req-approved-btn">Rejected</button>
                                    }
                                    
                                    { testerPopUp && requestId === reqObjInner?.requestId &&  
                                        <div className='testerListPopup' ref={testerpopRef}>
                                            <div className='testerSelectHing'>Select the tester</div>
                                            <div className='testerListMainDiv'>
                                                <div className='selectedTesterHolder' onClick={() => setTesterListFlag(true)}>
                                                { selectedQA !== null && <div className="taskOwnerImage"><img id="taskOwnerImage" src={profile} alt="" /></div>}
                                                <div>{selectedQA !== null ? selectedQA?.empName : 'Select tester here...'}</div>
                                                </div>
                                                {testerListFlag && requestId === reqObjInner?.requestId &&  
                                                    <div className='testerListHolder' ref={testerListRef}>
                                                        { reqObjInner && reqObjInner?.qaList?.length > 0 && reqObjInner?.qaList?.map((obj) => (
                                                             <div className='testerContent' onClick={() => {
                                                                setSelectedQA(obj)
                                                                setTesterListFlag(false)
                                                                dispatch(setResetObject(reqObj))
                                                                dispatch(setReqObj(reqObj));
                                                            }}>
                                                                <div className="taskOwnerImage"><img id="taskOwnerImage" src={profile} alt="" /></div>
                                                                <div>{obj.empName}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                            <div className='selectedTesterBtns'>
                                                <button style={{ background: '#FF8402', cursor: "auto", fontSize: '15px', cursor: 'pointer'}} className='sendToTestBtn' id="req-approved-btn" onClick={() => {
                                                    if(selectedQA === null){
                                                        toast.error('Please select the tester',{autoClose: 2000})
                                                        return
                                                    }else{
                                                        setSendLoader(true)
                                                    }
                                                    const sendData = {
                                                        "requestId" :reqObjInner?.requestId,
                                                         "empId" : self.empId,
                                                         "statusId" : 1,
                                                         "testerId" : selectedQA?.empId
                                                    }

                                                    axios.put(`${url}task/req/status/change`, sendData, {
                                                        headers: storedHeaders
                                                    })
                                                        .then(response => {
                                                            if (response.status === 200) {
                                                                setAppLoader(false)
                                                                setIsRefRotate(false)
                                                                setIsRefRotate(false)
                                                                setSendLoader(false)
                                                                setSelectedQA(null)
                                                                const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqObjInner.requestId);
                                                                dispatch(setReqObj(updatedReqObj))
                                                                setIsRefRotate(false)
                                                                try {
                                                                    axios.get(`${url}task/pending-req`, {
                                                                        headers: storedHeaders
                                                                    })
                                                                        .then((response) => {
                                                                            if (response && response.data) {
                                                                                setSendLoader(false)
                                                                                setSelectedQA(null)
                                                                                dispatch(setRequestCount(response.data))
                                                                                setTesetrPopup(false)
                                                                            } else {
                                                                                if (response.data === 0) {
                                                                                    dispatch(setRequestCount(response.data))
                                                                                }
                                                                            }
                                                                        }).catch((err) => {
                                                                            setSendLoader(false)
                                                                            setAppLoader(false)
                                                                            if (err.response && err.response.status === 500) {
                                                                                navigate("/server-error")
                                                                            }
                                                                        })
        
                                                                } catch (err) {
                                                                    setAppLoader(false)
                                                                    setSendLoader(false)
                                                                    if (err.response && err.response.status === 500) {
                                                                        navigate("/server-error")
                                                                    }
        
                                                                }
        
                                                            }
                                                        })
                                                        .catch(error => {
                                                            setIsRefRotate(false)
                                                            setAppLoader(false)
                                                            setTesetrPopup(false)
                                                            if(error.request.status ===404){
                                                                const updatedReqObj = requestObj.filter(obj => obj.requestId !== reqObjInner.requestId);
                                                                dispatch(setReqObj(updatedReqObj))
                                                            }
                                                        })

                                                }}>{sendLoader && requestId === reqObjInner?.requestId  ? <div className="approvedLoader"></div> : 'Send'}</button>
                                                { selectedQA !== null && 
                                                    <button style={{ background: '#f0f0f0', cursor: "auto", color: 'black', fontSize: '15px',  cursor: 'pointer'}} id="req-approved-btn" className='sendToTestBtn' onClick={() => {setSelectedQA(null)}}>Clear</button>
                                                }
                                            </div>
                                        </div>
                                    }   

                                </div> : null}

                             
                        </div>
                    ))}
                    {
                        isLastPage || search !== "" ? null : (requestObj && requestObj.length > 0 ? <div className="loadmore-requests-container">
                            <div className='loadmore-requests' onClick={!loader ? async () => {
                                setLoader(true)
                                if (requestType === "empReq") {
                                    try {
                                        const response = await axios.get(`${url}task/req/get/all?page=${page + 1}&size=10&status=${reqStatus}`, { headers: storedHeaders })
                                        if (response && response.data) {
                                            if (!response.data.isLastPage) {
                                                setPage(page + 1)
                                            }
                                            const updatedReqObj = [...requestObj.map((obj) => obj)]
                                            updatedReqObj.push(...response.data.data.map((obj) => obj))
                                            dispatch(setReqObj(updatedReqObj));
                                            setIslastPage(response.data.isLastPage)
                                            setLoader(false)
                                            setIsRefRotate(false)
                                        }
                                    } catch (err) {
                                        
                                        setIsRefRotate(false)
                                    }
                                } else {
                                    var obj = {
                                        userId: self.userId,
                                        status: reqStatus
                                    };
                                    
                                    // if (isLastPage === false) {
                                    //     obj['page'] = 10;
                                    // }
                                    axios.post(`${url}task/req/my/all?page=${page}&size=${reqLength}`, obj, { headers: storedHeaders })
                                        .then(response => {
                                            if (response.request.status === 200) {
                                                const updatedReqObj = [...requestObj.map((obj) => obj)]
                                                updatedReqObj.push(...response.data.data.map((obj) => obj))
                                                dispatch(setReqObj(updatedReqObj));
                                                dispatch(setResetObject(updatedReqObj))
                                                setLoaderRequest(false)
                                                setLoader(false)
                                                setIslastPage(response?.data?.isLastPage)
                                                setIsRefRotate(false)
                                                if(response?.data?.isLastPage === false){
                                                    setPage(page + 1)
                                                }else{
                                                    setPage(0)
                                                }
                                            }
                                        })
                                        .catch(error => {
                                            setIsRefRotate(false)
                                        });
                                }
                            } : null}>{
                                    loader ? <div className='req-loader-button' /> : isLastPage === false ? <div className='load-more-text'>Load More <svg width="12" height="auto" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.33845 0.590253C4.43036 0.484195 4.42765 0.325932 4.33215 0.223087C4.22131 0.103723 4.03159 0.106345 3.92409 0.228726L2.25 2.13462L0.575908 0.228726C0.468411 0.106345 0.278686 0.103723 0.167848 0.223087C0.0723491 0.325932 0.0696354 0.484195 0.161553 0.590253L2.25 3L4.33845 0.590253Z" fill="#263238" />
                                    </svg> </div> : null
                                }
                            </div>
                        </div> : null)
                    }

                    {requestObj && requestObj.length === 0 &&  loader !== true && 
                        <div className='requestNotFound'>
                            <img src={noData} alt='/' />
                            <h1 style={{ color: '#FF8402' }}>Request Not Found</h1>
                        </div>
                    }
                </div>
            )
            }
        </div>
    )
}

export default Request