

import React, { useEffect, useRef, useState } from 'react'
import './LeaveDayView.css'
import { useDispatch } from 'react-redux'
import { setView, setFullDate, setOnLeaveDate, setUpdateLeaveObj, setEditeLeaveFlag, setLeaveEditeDate, setLeaveList, setLeaveManageHolidayDay } from '../../../../erpStore/actions'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { toast } from 'react-toastify'
import { stubFalse } from 'lodash-es'
import profile from '../../../../assets/images/profile.png'
import { useAsyncError } from 'react-router-dom'
import { Space, TimePicker } from "antd";
import { cheerfulFiestaPaletteLight } from '@mui/x-charts'


function LeaveDayView() {

  const dispatch = useDispatch()
  const selfInfo = useSelector((state) => state.data.selfData)
  const url = useSelector((state) => state.data.apiUrl)
  const stringHeaders = window.localStorage.getItem('myHeaders');
  const storedHeaders = JSON.parse(stringHeaders);
  const [attendenceList, setAttendenceList] = useState([])
  const onLeaveDate = useSelector((state) => state.data.onLeaveDate)
  const dateRouteRef = useRef(null);
  const fdSplit = onLeaveDate?.toString().split(", ")
  const [tempAttObj, setTempAttObj] = useState(null)
  const [isInTimeFlag, setIsInTimeFlag] = useState(false)
  const [inTimeIndex, setInTimeIndex] = useState(null)
  const [outTimeIndex, setOutTimeIndex] = useState(null)

  const date = new Date(onLeaveDate);
  // Get the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  const [routeDate, setRouteDate] = useState(formattedDate)

  const [isDateRoute, setIsDateRoute] = useState(false)
  const [crudRowIndex, setCrudRowIndex] = useState(null)
  const [isCrudFlag, setIsCrudFlag] = useState(false)
  const crudRef = useRef(null)
  const [deleteFlag, setDeleteFlag] = useState(stubFalse)
  const updateLeaveObj = useSelector((state) => state.data.updateLeaveObj)
  const [tempAttendenceList, setTempAttendenceList] = useState([])
  const leaveList = useSelector((state) => state?.data?.leaveList)
  const [refreshClicked, setRefreshClicked] = useState(false)
  const [loader, setLoader] = useState(false)
  const getAllLeaveOnDate = () => {

    const months = {
      'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
      'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
    };



    // Split the date string into components
    const parts = onLeaveDate?.split(' ');
    const day = parts[1].replace(',', '');
    const month = months[parts[2]];
    const year = parts[3];

    // Format the date into YYYY-MM-DD
    const tempOnLeaveDate = `${year}-${month}-${day.padStart(2, '0')}`;
    setLoader(true)
    setTempAttendenceList([])
    dispatch(setLeaveList([]))
    axios.post(`${url}leave/data-day`, { "date": tempOnLeaveDate }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          setIsDateRoute(false)
          setAttendenceList(response.data.attendance)
          setTempAttendenceList(response.data.attendance)
          dispatch(setLeaveList(response.data.attendance))
          dispatch(setLeaveManageHolidayDay(response.data.holiday))
          setLoader(false)
          setRefreshClicked(false)
        
        }
      })
      .catch(error => {
        setIsDateRoute(false)
        setLoader(false)
      })
  }

  useEffect(() => {
    getAllLeaveOnDate()
  }, [onLeaveDate])

  useEffect(()=> {
    setTempAttendenceList(leaveList)
  }, [leaveList])

  const handlePrevDate = () => {
    const currentDate = moment(fdSplit[1]);
    // dispatch(setLoader(true))
    const previousDate = currentDate.subtract(1, 'day');
    dispatch(setOnLeaveDate(previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year()))
    window.sessionStorage.setItem('date', previousDate.format('dddd') + ", " + previousDate.date().toString().padStart(2, "0") + " " + previousDate.format('MMM') + " " + previousDate.year())
    setRouteDate(previousDate._d)
  }

  const handleNextDate = () => {
    const currentDate = moment(fdSplit[1]);
    // dispatch(setLoader(true))
    const nextDate = currentDate.add(1, 'day')
    dispatch(setOnLeaveDate(nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year()))
    window.sessionStorage.setItem('date', nextDate.format('dddd') + ", " + nextDate.date().toString().padStart(2, "0") + " " + nextDate.format('MMM') + " " + nextDate.year())
    setRouteDate(nextDate._d)

  }

  const handleRouteDate = (e) => {
    const date = new Date(e.target.value);
    // Get the day of the week, day of the month, month, and year from the date object
    const weekDay = date.toLocaleString('default', { weekday: 'long' });
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    // Format the date as "Weekday, DD MMM YYYY"
    const tempString =  `${weekDay}, ${day} ${month} ${year}`;
    dispatch(setOnLeaveDate(tempString))
   
  }

  useEffect(() => {
    function handleOutsideClick(event) {
      if (dateRouteRef && dateRouteRef.current && !dateRouteRef.current.contains(event.target)) {
        setIsDateRoute(false)
      } else if (crudRef && crudRef.current && !crudRef.current.contains(event.target)) {
        setIsCrudFlag(false)
        setUpdateLeaveObj(null)
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  const setRowBackgroundColor = (attObj) => {
    if (attObj.leave?.leaveFormat === "FULLDAY") {
      return "#FFDFDF"
    } else if (attObj?.leave === null) {
      return "none"
    } else {
      return "rgb(98, 63, 173, 0.2)"
    }
  }

  const setRowColor = (attObj) => {
    if (attObj.leave?.leaveFormat === "FULLDAY") {
      return "#E70000"
    } else if (attObj?.leave === null) {
      return "#263238"
    } else {
      return "#623FAD"
    }
  }

  const deleteLeave = () => {
    axios.put(`${url}leave/delete-leave`, { "leaveId": updateLeaveObj.leave.leaveId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          tempAttendenceList[crudRowIndex].leave = null
          setTempAttendenceList(tempAttendenceList)
          dispatch(setLeaveList(tempAttendenceList))
          setDeleteFlag(false)
          dispatch(setUpdateLeaveObj(null))
          toast.success("leave delete successfully", { autoClose: 2000 })
        }
      })
      .catch(error => {
        toast.error('Error Occured', { autoClose: 2000 })
        dispatch(setUpdateLeaveObj(null))
        setDeleteFlag(false)
      })
  }

  const covertStringFormat = (stringFormat) => {
    return stringFormat === 'FULLDAY' ? 'Full Day Leave' : stringFormat === 'HALFDAY1' ? 'First Half Leave' : 'Second Half Leave'
}

  function convertTimeFormat(timeString) {
    const [hours, minutes] = timeString.split(':');

    let hourNum = parseInt(hours, 10);
    const ampm = hourNum >= 12 ? 'pm' : 'am';

    hourNum = hourNum % 12;
    hourNum = hourNum === 0 ? 12 : hourNum;

    const hourStr = hourNum < 10 ? '0' + hourNum : hourNum;
    let minuteStr = ''
    if (minutes === '00') {
      minuteStr = '00'
    } else {
      minuteStr = minutes < 10 && minutes !== 0 ? '' + minutes : minutes;
    }
    return hourStr + ':' + minuteStr + ' ' + ampm;
  }

  function workDurationConvert(startTime, endTime) {
    var start = parseTime(startTime);
    var end = parseTime(endTime);
    

    var diffMinutes = (end - start) / (1000 * 60);
    return (diffMinutes / 60 < 10 ? `${diffMinutes / 60} h` : diffMinutes / 60 < 10 + 'h') ? (diffMinutes / 60 < 10 ? `${diffMinutes / 60} h` : diffMinutes / 60 < 10 + 'h') : 0 + ' h';
  }

  function parseTime(timeStr) {
    var time = new Date("2000-01-01 " + timeStr);
    return time;
  }

  const handleFutureDate = () => {
    const date = new Date(onLeaveDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const date1 = new Date();
    const year1 = date1.getFullYear();
    const month1 = String(date1.getMonth() + 1).padStart(2, '0');
    const day1 = String(date1.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}` === `${year1}-${month1}-${day1}` ? true : false;
  }


  const parseDateString = (dateString) => {
    const [weekday, day, month, year] = dateString.split(' ');
    const monthMap = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',  
      Oct: '10',
      Nov: '11',
      Dec: '12'
    };

    const monthNumber = monthMap[month];
    return `${year}-${monthNumber}-${day.padStart(2, '0')}`;
  };

  useEffect(() => {
    setTempAttendenceList(tempAttendenceList)
    setAttendenceList(tempAttendenceList)
  }, [tempAttendenceList, attendenceList])

  const handleInTime = (time, timeString) => {

    const index = tempAttendenceList.findIndex(user => user.userId.toString() === tempAttObj.userId.toString());
    if(new Date(onLeaveDate) > new Date()){
      tempAttendenceList[index].inTime = null
      setTempAttendenceList(tempAttendenceList)
      toast.error("Can't update the attendence of the future date", { autoClose: 2000 })
      return
    }
    
    if(tempAttObj?.leave?.leaveType === 'Excused' && tempAttObj?.leave?.leaveFormat === 'FULLDAY'){
      tempAttendenceList[index].inTime = null
      setTempAttendenceList(tempAttendenceList)
      toast.error(`${tempAttObj?.empFullName} had leave can't update attendence`);
      return
    }
    

    let hour = time.$H < 10 ? '0' + time.$H : time.$H
    let minute = time.$m < 10 ? '0' + time.$m : time.$m
    let seconds = time.$s < 10 ? '0' + time.$s : time.$s
    let temptimeString = `${hour}:${minute}:${seconds}`;
    axios.put(`${url}attendance/update-attendance`, { "attendanceDate": parseDateString(onLeaveDate), "inTime": temptimeString, "userId": tempAttObj.userId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
            if (index !== -1) {
                const updatedAttendenceList = [...tempAttendenceList];
                if (tempAttObj?.leave?.leaveFormat === 'FULLDAY' && tempAttObj?.leave?.leaveType === 'Unexcused') {
                    updatedAttendenceList[index].leave = null;
                }
                updatedAttendenceList[index].inTime = response.data.inTime;
                setTempAttendenceList(updatedAttendenceList);
                setAttendenceList(tempAttendenceList)
                setIsInTimeFlag(false);
                setInTimeIndex(null);
                toast.success('In-time updated successfully', { autoClose: 2000 });
            }
        }
        
      })
      .catch(error => {
        setIsInTimeFlag(false)
      })
  };


  const handleOutTime = (time, timeString) => {

    const index = tempAttendenceList.findIndex(user => user.userId.toString() === tempAttObj.userId.toString());

    if(new Date(onLeaveDate) > new Date()){
      tempAttendenceList[index].outTime = null
      setTempAttendenceList(tempAttendenceList)
      setAttendenceList(tempAttendenceList)
      toast.error("Can't update the attendence of the future date", { autoClose: 2000 })
      return
    }

    if(tempAttObj?.leave?.leaveType === 'Excused' && tempAttObj?.leave?.leaveFormat === 'FULLDAY'){
      tempAttendenceList[index].outTime = null
      setTempAttendenceList(tempAttendenceList)
      toast.error(`${tempAttObj?.empFullName} had leave can't update attendence`);
      return
    }

    if(tempAttObj.inTime === null ){
      toast.error("Please update the in time first", {autoClose: 2000})
      return
    }

    let hour = time.$H < 10 ? '0' + time.$H : time.$H
    let minute = time.$m < 10 ? '0' + time.$m : time.$m
    let seconds = time.$s < 10 ? '0' + time.$s : time.$s
    let temptimeString = `${hour}:${minute}:${seconds}`;
    axios.put(`${url}attendance/update-attendance`, { "attendanceDate": parseDateString(onLeaveDate), "outTime": temptimeString, "userId": tempAttObj.userId }, { headers: storedHeaders })
      .then(response => {
        if (response.request.status === 200) {
          tempAttendenceList[index].outTime = response.data.outTime
          setTempAttendenceList(tempAttendenceList)
          setAttendenceList(tempAttendenceList)
          toast.success('out Time updated successfully', {autoClose: 2000})
          setIsInTimeFlag(false)
          setOutTimeIndex(null)
        }
      })
      .catch(error => {
        setIsInTimeFlag(false)
        
      })
  }

  useEffect(() => { 
    setTempAttendenceList(tempAttendenceList)
    setAttendenceList(tempAttendenceList)
  }, [tempAttendenceList, attendenceList])

  return (
    <div className='LeaveDayViewMainContainer'>
      <div className='leaveDayViewCalenderHeader'>
        <div className='dayViewDateSeletor LeavedayViewDateSelector'>
          <svg onClick={handlePrevDate} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
            <path d="M5.19231 0L6 0.916667L1.73077 5.5L6 10.0833L5.19231 11L4.76837e-07 5.5L5.19231 0Z" fill="#FF8402" />
          </svg>
          <div className='date-holder-dashboard' onClick={() => { setIsDateRoute(true) }}>
            <p onClick={() => ""}>{onLeaveDate}</p>
            {isDateRoute &&
              <div className='calender-holder-dashboard' ref={dateRouteRef}>
                <Calendar value={moment(routeDate)._d} inline onChange={(e) => {
                  handleRouteDate(e)  
                  setRouteDate(e.target.value)
                }} unstyled={false} />
              </div>
            }

          </div>
          <svg onClick={handleNextDate} width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
            <path d="M0.807692 0L0 0.916667L4.26923 5.5L0 10.0833L0.807692 11L6 5.5L0.807692 0Z" fill="#FF8402" />
          </svg>
        </div>
        <div className="viewBtns viewDayView-btn">
          <svg className={refreshClicked ? 'getAllLeaveDate' : null} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="refresh-icon"
            onClick={() => {
              setRefreshClicked(true)
              getAllLeaveOnDate()
            }}>
            <path d="M1 4V10H7" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23 20V14H17" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.49 8.99959C19.9828 7.56637 19.1209 6.28499 17.9845 5.27501C16.8482 4.26502 15.4745 3.55935 13.9917 3.22385C12.5089 2.88834 10.9652 2.93393 9.50481 3.35636C8.04437 3.77879 6.71475 4.5643 5.64 5.63959L1 9.99959M23 13.9996L18.36 18.3596C17.2853 19.4349 15.9556 20.2204 14.4952 20.6428C13.0348 21.0652 11.4911 21.1108 10.0083 20.7753C8.52547 20.4398 7.1518 19.7342 6.01547 18.7242C4.87913 17.7142 4.01717 16.4328 3.51 14.9996" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <button style={{ backgroundColor: handleFutureDate() ? '#FFF6DF' : '#fff', cursor: 'pointer' }} onClick={() => {
              const date = new Date();
              const weekDay = date.toLocaleString('default', { weekday: 'long' });
              const day = date.getDate().toString().padStart(2, '0');
              const month = date.toLocaleString('default', { month: 'short' });
              const year = date.getFullYear();
              const tempString =  `${weekDay}, ${day} ${month} ${year}`;
              dispatch(setOnLeaveDate(tempString))
          }} >Today</button>
          <button onClick={() => {
            dispatch(setView('month'))
            window.sessionStorage.setItem("view", 'month')
          }} style={{ marginLeft: '30px', cursor: 'pointer' }}>Month</button>
          <button style={{ background: '#FFF6DF', marginLeft: '3px', cursor: 'pointer' }}>Day</button>
        </div>
      </div>
      <div className='leaveDayViewTableView'>
        <div className='leaveTableViewHead'>
          <div className='sr'>sr</div>
          <div className='empId'>empId</div>
          <div className='name'>Name</div>
          <div className='department'>Department</div>
          <div className='InTime'>In Time</div>
          <div className='outTime'>Out Time</div>
          <div className='workTime'>Work Time</div>
        </div>

        <div className='leaveDayViewTbody'>
          <div className='leaveDayViewTbodyScroller'>
          {tempAttendenceList && tempAttendenceList.length > 0 && tempAttendenceList.map((attObj, index) => (
            <div className='myRow'>
              <div className='leaveTableViewHeadTbody' style={{ background: setRowBackgroundColor(attObj), color: setRowColor(attObj) }}>
                <div className='sr-scanner' >{index + 1}</div>
                <div className='empId-scanner'>
                  {attObj.userId}
                </div>

                <div className='nameMyRow-scanner'>
                  <div className='holdAttNamehold'>
                    <div className='taskOwnerImage'><img id="taskOwnerImage" src={attObj.empImg !== null ? attObj.empImg : profile} alt='' /></div>
                    <div>{attObj.empFullName}</div>
                  </div>
                </div>

                <div className='department'>{attObj.department}</div>
                <div className='InTime-scanner'>
                    <Space wrap >
                      <TimePicker autoClose className='tempTimePicker' placeholder={attObj?.inTime !== null ? `${convertTimeFormat(attObj?.inTime)}` : 'In Time'} use24Hours minuteStep={1} secondStep={1} hourStep={1} format={'h:mm a'} onClick={() => setTempAttObj(attObj)} onChange={handleInTime} />
                    </Space>
                </div>
                <div className='outTime-scanner'>
                    <Space wrap>
                      <TimePicker className='tempTimePicker' placeholder={attObj?.outTime !== null ? `${convertTimeFormat(attObj?.outTime)}` : 'Out Time'} use24Hours minuteStep={1} secondStep={1} hourStep={1} format={'h:mm a'} onClick={() => setTempAttObj(attObj)} onChange={handleOutTime} />
                    </Space>
                </div>
                <div className='status-scanner'>
                  {attObj.leave !== null ?
                    (
                      <div className='status-scanner' >
                        {(attObj.leave.leaveFormat !== 'FULLDAY') ? `${parseInt(workDurationConvert(attObj.inTime, attObj.outTime))} : ${covertStringFormat(attObj.leave.leaveFormat)}` : `${covertStringFormat(attObj.leave.leaveFormat)}`}
                        <svg width="23" height="6" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                          setCrudRowIndex(index)
                          setIsCrudFlag(true)
                          dispatch(setUpdateLeaveObj(attObj))
                        }}>
                          <circle cx="2" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                          <circle cx="10" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                          <circle cx="18" cy="2" r="2" fill="#263238" fill-opacity="0.8" />
                        </svg>
                      </div>
                    )
                    :
                    (
                      <div className='status-scanner' style={{ gap: '0px', display: 'flex', justifyContent: 'center', alignItem: 'center', fontFamily: 'Roboto', width: '300px' }}>
                        {`${parseInt(workDurationConvert(attObj.inTime, attObj.outTime))} h`}
                      </div>
                    )
                  }

                  {isCrudFlag && crudRowIndex === index &&
                    <div className='project-crud leaveCrudsList' ref={crudRef}>
                      <div className='project-editecrd' onClick={() => {
                        dispatch(setEditeLeaveFlag(true))
                      }}>
                        <svg width="15" height="15" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.78813 0.954054C10.2602 0.492902 10.8951 0.236434 11.5551 0.240277C12.215 0.24412 12.8469 0.507963 13.3135 0.974582C13.7802 1.4412 14.0441 2.07297 14.0479 2.73285C14.0518 3.39274 13.7953 4.02753 13.3341 4.49956L12.7702 5.06336L9.22425 1.51786L9.78813 0.954054ZM8.62834 2.11454L1.88624 8.85579C1.61036 9.13197 1.41886 9.48101 1.33416 9.86205L0.594127 13.191C0.578648 13.2602 0.580917 13.3323 0.600723 13.4004C0.620528 13.4686 0.657229 13.5306 0.707419 13.5808C0.75761 13.631 0.819665 13.6677 0.887826 13.6875C0.955987 13.7073 1.02805 13.7096 1.09732 13.6941L4.40642 12.9584C4.80041 12.8707 5.16121 12.6724 5.44652 12.387L12.1743 5.66004L8.62834 2.11454Z" fill="#FF8402" />
                        </svg>
                        <p style={{ fontSize: '15px' }}>Edit Leave</p>
                      </div>
                      <div className='project-editecrd' onClick={() => {
                        setDeleteFlag(true)

                      }}>
                        <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.5935 12.3586C1.5935 13.1814 2.30167 13.8546 3.16721 13.8546H9.46207C10.3276 13.8546 11.0358 13.1814 11.0358 12.3586V4.87862C11.0358 4.05582 10.3276 3.38262 9.46207 3.38262H3.16721C2.30167 3.38262 1.5935 4.05582 1.5935 4.87862V12.3586ZM11.0358 1.13862H9.06864L8.50997 0.607545C8.36834 0.472905 8.16375 0.390625 7.95917 0.390625H4.67011C4.46553 0.390625 4.26094 0.472905 4.11931 0.607545L3.56064 1.13862H1.5935C1.16073 1.13862 0.806641 1.47522 0.806641 1.88662C0.806641 2.29803 1.16073 2.63462 1.5935 2.63462H11.0358C11.4686 2.63462 11.8226 2.29803 11.8226 1.88662C11.8226 1.47522 11.4686 1.13862 11.0358 1.13862Z" fill="#FF8402" />
                        </svg>
                        <p style={{ fontSize: '15px' }} >Delete Leave</p>

                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
      {deleteFlag &&

        <div className="deleteTaskPopup">
          <div className="deleteTaskSurityCard">
            <div className="deleteTaskSurityCard_heading"><p>Do you want to delete leave?</p></div>
            <div className="confirm_btn_deletetask">
              <button style={{ backgroundColor: 'rgba(255, 132, 2, 1)', color: 'rgba(255, 255, 255, 1)' }} onClick={() => {
                deleteLeave()
                setIsCrudFlag(false)
              }}>Yes</button>
              <button style={{ backgroundColor: 'rgba(232, 232, 232, 1)', color: 'rgba(38, 50, 56, 1)' }}
                onClick={() => {
                  setIsCrudFlag(false)
                  setDeleteFlag(false)
                }}
              >No</button>
            </div>
          </div>
        </div>
      }
        {
          loader && <div class="loaderIdProjectsLoad">
              <div class="leaveDayview-loader"></div>
          </div>
        }
    </div>
  )
}

export default LeaveDayView