// initialState.js
import moment from 'moment'
const routes = window.sessionStorage.getItem("route")
const initialState = {
  
  apiUrl: 'https://erp.biencaps.in/erp/',          // Production url
  // apiUrl: 'https://erp.biencaps.in:10004/erp/',    // Production test url
  // apiUrl: 'http://192.168.0.156:10002/erp/',          // Local host test url Subodh
  // apiUrl: 'http://192.168.0.187:10002/erp/',       // Local host test url Shivkumar
  
  dataObject: null,       
  resetObject: {},
  view: "month",
  fullDate: moment(),
  sortObject: null,
  newTask: {},
  selfData: window.localStorage.getItem("selfInfo")?JSON.parse(window.localStorage.getItem("selfInfo")):[],
  addDate: '',
  reset: 0,
  empView: false,
  empInfo: [],
  header: "Tasks",
  editForm: false,
  closureMail: false,
  addEmp: false,
  notifications: false,
  reminderNotify: false,
  route: routes ? routes : "mytask",
  addProject: false,
  projectlist: [],
  addPhase: "none",
  getLogs: false,
  project: [],
  requestObj: [],
  searchDateForMonth: null,
  milestoneObj: [],
  addMeeting: null,
  projectTaskFlow: '',
  showNotificationBanner: Notification.permission === 'default',
  message: '',
  logout: false,
  mtiID: true,
  updateEmp: [],
  editeKey: true,
  empSelectedTag: true,
  randomLink: "/dashboard",
  reqDenyPopUp: false,
  reAskOption: false,
  denyRequestObj: null,
  allEmployee: [],
  searchedTaskMonth: [],
  searchedDate: new Date(),
  levelSearch: [],
  setting: false,
  settingView: "",
  changePass: false,
  loader: true,
  allEmployeeData: [],
  weekData: [],
  isEditStatus: false,
  dispatchAllTask: [],
  usedList: [],
  unusedList: [],
  statusList: [],
  priorityList: [],
  projectlistUpdate: [],
  tagList: [],
  assgineeList: [],
  verifiedList: [],
  priorityColorList: [],
  isEditPriority: false,
  departmentColorList: [],
  isEditDepartment: false,
  verificationColorList: [],
  isEditverification: false,
  unRdNotiCount: 0,
  reqObj: [],
  reqRejectI: [],
  taskDetailFalg: false,
  taskDetailObject: {},
  monthData: [],
  empLoadMore: false,
  closureProcedure: false,
  isClosureKey: false,
  analyticsData: null,
  analyticsEmp: null,
  analyticsEmpList: null,
  designationList: null,
  isEditVerification: null,
  isDetailAnalytics: true,
  analyticsStartDate: "",
  analyticsEndDate: '',
  levelInfoObject: '',
  updateProjectFlag: false,
  projectToUpadte: null,
  reqCount: 0,
  taskEmp: null,
  empJoiningDate: null,
  reqCountData: null,
  updatePrjFlagList: false,
  analyticsType: 'year',
  infoShow: false,
  projectAllTskFlag: false,
  isCommentView: false,
  leaveCalenderViewFlag: false,
  addHolidayFlag: false,
  addLeaveFlag: false,
  editeLeaveFlag: false,
  eidteHolidayFlag: false,
  leaveDayViewFlag: false,
  scannerViewFlag: false,
  attendenceMonthViewFlag: false,
  myLeaveStatusFlag: false,
  scannerAttendenceList: [],
  attendenceDayViewflag: false,
  attendenceMusteredFlag: false,
  onLeaveDate: null,
  updateLeaveObj: null,
  holidayListFlag: false,
  editeHolidayFlag: false,
  holidayCrudObj: null,
  musteredDate: null,
  musterdAttObj: null,
  isHolidayStatus : null,
  empIsLeaveStatus : null,
  dayViewdata : null,
  attendenceAnalyticsDate : null,
  leaveList : [],
  leaveManageHolidayDay : null,
  isPresentDay : null,
  levelAddTaskFlag: false,
  monthAddTaskFlag: false,
  weekAddTaskFlag: false,
  storeDateArray: [],
  levelMonthAddTaskFlag : false,
};
export default initialState;

