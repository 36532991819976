import React, { useState, useEffect } from 'react';
import empEmpty from "../../../assets/images/Group 1069addEmpEmpty.png"
import "./styleEmployee.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAddEmp, setAllEmployee, setEditForm, setEmpInfo, setEmpLoadMore } from '../../../erpStore/actions';


function AddEmployeeHome() {
    const [empList, setEmplist] = useState([]);
    const selfInfo = useSelector((state) => state.data.selfData);
    const apiUrl = useSelector((state) => state.data.apiUrl)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const empForm = useSelector((state) => state.data.addEmp)
    const allEmployee = useSelector((state) => state.data.allEmployee)
    const editForm = useSelector((state) => state.data.editForm)
    const [page, setPage] = useState(0);
    const isLastPage = useSelector((state) => state.data.empLoadMore);
    const [loader, setLoader] = useState(false);
    const [empLoader, setEmpLoader] = useState(false)
    const [isEmployeeLoader, setIsEmployeeLoader] = useState(false)


    const fetchData = (apiUrl, storedHeaders, setEmplist) => {

        setEmpLoader(true)
        setIsEmployeeLoader(true)
        const url = `${apiUrl}employee/get/all?page=${0}&size=20&key=${""}`;
        axios.get(url, {
            headers: storedHeaders,
        })
            .then(response => {
                setEmplist(response.data.data);
                window.localStorage.setItem("idCount", response.data.data.length);
                dispatch(setAllEmployee(response.data.data))
                setPage(page + 1)
                dispatch(setEmpLoadMore(response.data.isLastPage))
                setEmpLoader(false)
                setIsEmployeeLoader(false)

            })
            .catch((error) => {
                if (error.response.data.status === 401) {
                    setIsEmployeeLoader(false)

                    navigate('/login');
                }
                setIsEmployeeLoader(false)
                setEmpLoader(false)
            });
    };

    useEffect(() => {
        try {
            const stringHeaders = window.localStorage.getItem('myHeaders');
            const storedHeaders = JSON.parse(stringHeaders);
            if (allEmployee && allEmployee.length === 0) {
                fetchData(apiUrl, storedHeaders, setEmplist);
            }

        } catch (error) {

        }


    }, []);

    const loadMore = () => {
        setLoader(true)
        setIsEmployeeLoader(true)
        const stringHeaders = window.localStorage.getItem('myHeaders');
        const storedHeaders = JSON.parse(stringHeaders);
        const url = `${apiUrl}employee/get/all?page=${page}&size=20&key=${""}`;
        axios.get(url, {
            headers: storedHeaders,
        })
            .then(response => {
                setEmplist(response.data.data);
                window.localStorage.setItem("idCount", response.data.data.length);

                dispatch(setAllEmployee(response.data.data))
                setPage(page + 1)
                const updatedReqObj = [...allEmployee.map((obj) => obj)]
                updatedReqObj.push(...response.data.data.map((obj) => obj))
                dispatch(setAllEmployee(updatedReqObj));
                dispatch(setEmpLoadMore(response.data.isLastPage))
                setLoader(false)
                setIsEmployeeLoader(false)
            })
            .catch((error) => {
                if (error && error.response && error.response.data.status === 401) {
                    setIsEmployeeLoader(false)
                    navigate('/login');
                }
                setIsEmployeeLoader(false)

                setLoader(false)
            });
    }

    useEffect(() => {
        if (allEmployee && allEmployee.length > 0) {
            setEmplist(allEmployee)
        }
    }, [allEmployee, editForm])

    //Handling scrolling Area 
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleScroll = (e) => {
        const scrollValue = e.target.scrollLeft;
        setScrollLeft(scrollValue);
    };
    return (
        <div className='add-employee-home'>
            <div className='add-employee-home-container'>
                { isEmployeeLoader !== true && 
                    allEmployee && allEmployee.length === 0 ? <div className='emptyContainer'>
                        <img src={empEmpty} alt='/' />
                        <p><span>No data,</span> please add Employees</p>
                        <div className='AddEmpButtonEmpty' onClick={() => { dispatch(setAddEmp(true)) }}>Add Employees</div>
                    </div> : <div className='table-container'>
                        <table>
                            <thead className="header">
                                <tr>
                                    <th className="header-cell">Sr.</th>
                                    <th className="header-cell">Emp Id</th>
                                    <th className="header-cell" style={{ textAlign: "left", paddingLeft: "20px" }}>Name</th>
                                    <th className="header-cell">Joining Date</th>
                                    <th className="header-cell">Designation</th>
                                    <th className="header-cell">Department</th>
                                    <th className="header-cell">Role</th>
                                    <th className="header-cell">@Biencaps.com</th>
                                    <th className="header-cell">Mobile no</th>
                                </tr>
                            </thead>{
                                !empLoader ? <tbody className="body" onScroll={handleScroll}>
                                    {
                                        allEmployee && allEmployee.length > 0 &&
                                        allEmployee.map((employee, index) => (
                                            <tr className="row" style={{ color: employee.empStatus === "INACTIVE" ? "rgba(202, 3, 3, 1)" : null, background: selfInfo.empId === employee.empId ? "rgb(249 236 202)" : null }} key={employee.empId} onClick={(e) => {
                                                dispatch(setEditForm(true))
                                                dispatch(setEmpInfo(employee))
                                            }}>
                                                <td className="cell" style={{ width: "50px" }}>{index + 1}</td>
                                                <td className="cell">{employee.userId}</td>
                                                <td className='empName cell' style={{ textAlign: "left" }}>{employee.empFullName}</td>
                                                <td className='cell'>{employee.empJoiningDate}</td>
                                                <td className="cell">{employee.designation.designation}</td>
                                                <td className="cell">{employee.department.departmentName}</td>
                                                <td className="cell">{employee.role.length ? employee.role[0].roleName : null}</td>
                                                <td className="cell">{employee.empEmailOfficial}</td>
                                                <td className="cell">{employee.empMobile1}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody> : <tbody className="body" onScroll={handleScroll}>
                                    {
                                        allEmployee && allEmployee.length > 0 &&
                                        allEmployee.map((employee, index) => (
                                            <tr className="row" >
                                                <td className="cell" style={{ width: "50px" }}><div className='employee-loader'></div></td>
                                                <td className="cell"><div className='employee-loader'></div></td>
                                                <td className='empName cell' style={{ textAlign: "left" }}><div className='employee-loader-name'></div></td>
                                                <td className="cell"><div className='employee-loader-designation'></div></td>
                                                <td className="cell"><div className='employee-loader-department'></div></td>
                                                <td className="cell"><div className='employee-loader-role'></div></td>
                                                <td className="cell"><div className='employee-loader-email'></div></td>
                                                <td className="cell"><div className='employee-loader-mobile'></div></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            }


                        </table>
                        {!isLastPage &&  isEmployeeLoader !== true ?
                            <div class="loadmore-requests-container">
                                <div class="loadmore-requests" onClick={loadMore}>{loader ? <div className='req-loader-button' /> :
                                    <div class="load-more-text">Load More <svg width="12" height="auto" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.33845 0.590253C4.43036 0.484195 4.42765 0.325932 4.33215 0.223087C4.22131 0.103723 4.03159 0.106345 3.92409 0.228726L2.25 2.13462L0.575908 0.228726C0.468411 0.106345 0.278686 0.103723 0.167848 0.223087C0.0723491 0.325932 0.0696354 0.484195 0.161553 0.590253L2.25 3L4.33845 0.590253Z" fill="#263238"></path></svg>
                                    </div>
                                }

                                </div>
                            </div> : null
                        }

                        {
                            isEmployeeLoader && 
                            <div class="loaderIdProjectsLoad">
                                <div class="loaderEmployeeec"></div>
                            </div>
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default AddEmployeeHome
