// reducer.js
import { act } from 'react';
import {
  SET_ALL_DISPATCH_TASK, SET_API_URL, SET_DATA_OBJECT, SET_VIEW_OBJECT, SET_VIEW_ADDTASK, SET_FULLDATE, SET_INITIAL_DATA_OBJECT, SET_NEWTASK, SET_SELF, SET_DATE, SET_RESET, SET_EMPINFO, SET_EMPVIEW, SET_HEADER, SET_EDITFORM, SET_CLOSURE, SET_ADDEMP, SET_ROUTE, SET_ADDPROJECT, SET_PROJECTLIST, SET_ADD_PHASE, SET_SEARCH_FOR_MONTH, SET_GETLOGS, SET_PROJECT, SET_NOTIFICATIONS, SET_REQUEST_OBJECT, SET_MILESTONEOBJ, SET_ADDMEETING, SET_NOTIFICATION, SET_PROJECTTASKFLOW, SET_REMINDERS, SET_MESSAGE, SET_LOGOUT, SET_MTIID, SET_UPDATEEMP, SET_EDITEKEY, SET_DEFAULT_TAG, SET_RANDOMLINK, SET_RQTDENYPOPUP, SET_RQTDENYOBJ, SET_PREASkOPTN, SET_ALLEMPLOYEE, SET_SEARCHEDDATE, SET_SEARCHEDTASKMONTH, SET_LEVELSEARCH, SET_SETTING, SET_SETTINGVIEW, SET_CHANGEPASS, SET_LOADER, SET_WEEKDATA, SET_STATUSLIST, SET_ISEDITSTATUS, SET_USEDLIST, SET_UNUSEDLIST, SET_PROJECTLISTUPDATE,
  SET_PRIORITYLIST,
  SET_ASSINEELIST,
  SET_VERIFIEdLIST,
  SET_TAGSLIST,
  SET_PRIORITYCOLORLIST,
  SET_ISEDITPRIORITY,
  SET_ISEDITDEPARTMENT,
  SET_DEPARTMENTCOLORLIST,
  SET_ISEDITVERIFICATION,
  SET_VERIFICATIONCOLORLIST,
  SET_UNRDNOTICOUNT,
  SET_REQOBJ,
  SET_REQREJECTI,
  SET_TASKDTLOBJ,
  SET_TASKDTLFLAG,
  SET_MONTHDATA,
  SET_EMPLOADMORE,
  SET_CLOSUREPROCEDURE,
  SET_ISCLOSUREKEY,
  SET_ANALYTICSDATA,
  SET_ANALYTICSEMP,
  SET_ANALYTICSEMPLIST,
  SET_DESIGNATIONLIST,
  SET_ISEDITDESIGNATION,
  SET_ISDETAILANALYTICS,
  SET_ANALYTICSSTARTDATE,
  SET_ANALYTICSENDDATE,
  SET_LEVELINFOOBJECT,
  SET_UPDATEPRJFLAG,
  SET_REQUESTCOUNT,
  SET_UPDATE_PROJECT,
  SET_TASKEMP,
  SET_INITIALSTATE,
  SET_EMPJOININGDATE,
  SET_REQCOUNTDATA,
  SET_PRJUPDATEFLAG,
  SET_INFOSHOW,
  SET_ANALYTICSTYPE,
  SET_PRJALLTSKFLAG,
  SET_ISCOMMENTVIEW,
  SET_LEAVECLANEDERVIEWFLAG,
  SET_ADDHOLIDAYFLAG,
  SET_ADDLEAVEFLAG,
  SET_LEAVEDAYVIEWFLAG,
  SET_SCANNERVIEWFLAG,
  SET_ATTENDENCEMONTHVIEWFLAG,
  SET_MYLEAVESTATUSFLAG,
  SET_SCANNERATTENDENCELIST,
  SET_ATTENDMUSTEREDFLAG,
  SET_ONLEAVEDATE,
  SET_UPDATELEAVEOBJ,
  SET_EDITELEAVEFLAG,
  SET_HOLIDAYLISTFLAG,
  SET_EDITEHOLIDAY,
  SET_HOLIDAYCRUDOBJ,
  SET_MUSTEREDDATE,
  SET_MUSTEREDOBJ,
  SET_ISHOLIDAYSTATUS,
  SET_EMPISLEAVESTATUS,
  SET_ATTANALAYTICSDATE,
  SET_LEAVELIST,
  SET_LEAVEMANAGEHOLIDAYDAY,
  SET_ISPRESENTDAY,
  SET_LEVELADDTASKFLAG,
  SET_MONTHADDTASKFLAG,
  SET_WEEKADDTASKFLAG,
  SET_STOREDATEARRAY,
  SET_LEVELMONTHADDTASKFALG
} from './actionTypes';
import { setEmpJoiningDate, setIsCommentView } from './actions';
import initialState from './initialState';
import { caES } from '@mui/x-date-pickers/locales';

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_API_URL:
      return { ...state, apiUrl: action.payload };

    case SET_INITIAL_DATA_OBJECT:
      return {
        ...state,
        dataObject: action.payload
      }

    case SET_DATA_OBJECT:
      let sortedObject = null;
      if (action.status === 0) {
        let tasks = action.payload.data
        const sortedTasksByStatus = tasks.sort((a, b) => b.statusEntity.statusId - a.statusEntity.statusId);
        const newDataObjectByStatus = {
          ...action.payload,
          data: sortedTasksByStatus,
        };
        sortedObject = newDataObjectByStatus
      } else if (action.status === 1) {
        let tasks = action.payload.data
        const sortedTasksByStatus = tasks.sort((a, b) => b.priorityEntity.priorityId - a.priorityEntity.priorityId);
        const newDataObjectByStatus = {
          ...action.payload,
          data: sortedTasksByStatus,
        };
        sortedObject = newDataObjectByStatus

      } else if (action.status === 2) {
        let tasks = action.payload.data;
        // Define a custom sorting function

        // Sort tasks using the custom sorting function
        const sortedTasksByStatus = tasks.sort((a, b) => {
          if (a.projectEntity === null && b.projectEntity === null) {
            return (a.taskTitle || '').localeCompare(b.taskTitle || '');
          } else if (a.projectEntity === null) {
            // If a's project entity is null, move it to the end
            return 1;
          } else if (b.projectEntity === null) {
            // If b's project entity is null, move it to the end
            return -1;
          } else {
            // Otherwise, sort alphabetically by project name
            return a.projectEntity.projectName.localeCompare(b.projectEntity.projectName);
          }
        });

        const newDataObjectByStatus = {
          ...action.payload,
          data: sortedTasksByStatus,
        };
        sortedObject = newDataObjectByStatus

      } else if (action.status === 3) {
        let tasks = action.payload.data
        const sortedTasksByStatus = tasks.sort((a, b) => new Date(a.taskDueDate) - new Date(b.taskDueDate));
        const newDataObjectByStatus = {
          ...action.payload,
          data: sortedTasksByStatus,
        };
        sortedObject = newDataObjectByStatus
      } else if (action.status === 4) {
        sortedObject = state.dataObject
      } else if (action.status === 5) {
        sortedObject = state.dataObject
        const newDataObjectBySearch = {
          ...action.payload,
          data: action.payload.data
        }
        sortedObject = newDataObjectBySearch
      }
      return { ...state, sortObject: sortedObject };

    case SET_VIEW_OBJECT:
      return { ...state, view: action.payload };
    case SET_VIEW_ADDTASK:
      return { ...state, addTask: action.payload };
    case SET_FULLDATE:
      return { ...state, fullDate: action.payload };
    case SET_NEWTASK:
      return { ...state, newTask: action.payload };
    case SET_SELF:
      return { ...state, selfData: action.payload };
    case SET_DATE:
      return { ...state, addDate: action.payload };
    case SET_RESET:
      return { ...state, reset: action.payload };
    case SET_EMPINFO:
      return { ...state, empInfo: action.payload };
    case SET_EMPVIEW:
      return { ...state, empView: action.payload };
    case SET_HEADER:
      return { ...state, header: action.payload };
    case SET_EDITFORM:
      return { ...state, editForm: action.payload };
    case SET_CLOSURE:
      return { ...state, closureMail: action.payload };
    case SET_REMINDERS:
      return { ...state, reminderNotify: action.payload }
    case SET_ADDEMP:
      return { ...state, addEmp: action.payload };
    case SET_ROUTE:
      return { ...state, route: action.payload };
    case SET_ADDPROJECT:
      return { ...state, addProject: action.payload };
    case SET_PROJECTLIST:
      return { ...state, projectlist: action.payload };
    case SET_ADD_PHASE:
      return { ...state, addPhase: action.payload };
    case SET_GETLOGS:
      return { ...state, getLogs: action.payload };
    case SET_PROJECT:
      return { ...state, project: action.payload };
    case SET_SEARCH_FOR_MONTH:
      return { ...state, searchDateForMonth: action.payload };
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload }
    case SET_REQUEST_OBJECT:
      return { ...state, requestObj: action.payload };
    case SET_MILESTONEOBJ:
      return { ...state, milestoneObj: action.payload };
    case SET_ADDMEETING:
      return { ...state, addMeeting: action.payload };
    case SET_NOTIFICATION:
      return { ...state, showNotificationBanner: action.payload };
    case SET_PROJECTTASKFLOW:
      return { ...state, projectTaskFlow: action.payload };
    case SET_MESSAGE:
      return { ...state, message: action.payload };
    case SET_LOGOUT:
      return { ...state, logout: action.payload }
    case SET_MTIID:
      return { ...state, mtiID: action.payload }
    case SET_UPDATEEMP:
      return { ...state, updateEmp: action.payload }
    case SET_EDITEKEY:
      return { ...state, editeKey: action.payload }
    case SET_RANDOMLINK:
      return { ...state, randomLink: action.payload }
    case SET_DEFAULT_TAG:
      return { ...state, empSelectedTag: action.payload }
    case SET_RQTDENYPOPUP:
      return { ...state, reqDenyPopUp: action.payload }
    case SET_RQTDENYOBJ:
      return { ...state, denyRequestObj: action.payload }
    case SET_PREASkOPTN:
      return { ...state, reAskOption: action.payload }
    case SET_ALLEMPLOYEE:
      return { ...state, allEmployee: action.payload }
    case SET_SEARCHEDDATE:
      return { ...state, searchedDate: action.payload }
    case SET_SEARCHEDTASKMONTH:
      return { ...state, searchedTaskMonth: action.payload }
    case SET_LEVELSEARCH:
      return { ...state, levelSearch: action.payload }
    case SET_SETTING:
      return { ...state, setting: action.payload }
    case SET_SETTINGVIEW:
      return { ...state, settingView: action.payload }
    case SET_CHANGEPASS:
      return { ...state, changePass: action.payload }
    case SET_LOADER:
      return { ...state, loader: action.payload }
    case SET_WEEKDATA:
      return { ...state, weekData: action.payload }
    case SET_ALL_DISPATCH_TASK:
      return { ...state, dispatchAllTask: action.payload }
    case SET_STATUSLIST:
      return { ...state, statusList: action.payload }
    case SET_PRIORITYLIST:
      return { ...state, priorityList: action.payload }
    case SET_PROJECTLISTUPDATE:
      return { ...state, projectlistUpdate: action.payload }
    case SET_ASSINEELIST:
      return { ...state, assgineeList: action.payload }
    case SET_TAGSLIST:
      return { ...state, tagList: action.payload }
    case SET_VERIFIEdLIST:
      return { ...state, verifiedList: action.payload }
    case SET_USEDLIST:
      return { ...state, usedList: action.payload }
    case SET_UNUSEDLIST:
      return { ...state, unusedList: action.payload }
    case SET_ISEDITSTATUS:
      return { ...state, isEditStatus: action.payload }
    case SET_PRIORITYCOLORLIST:
      return { ...state, priorityColorList: action.payload }
    case SET_ISEDITPRIORITY:
      return { ...state, isEditPriority: action.payload }
    case SET_ISEDITDEPARTMENT:
      return { ...state, isEditDepartment: action.payload }
    case SET_DEPARTMENTCOLORLIST:
      return { ...state, departmentColorList: action.payload }
    case SET_ISEDITVERIFICATION:
      return { ...state, isEditVerification: action.payload }
    case SET_VERIFICATIONCOLORLIST:
      return { ...state, verificationColorList: action.payload }
    case SET_UNRDNOTICOUNT:
      return { ...state, unRdNotiCount: action.payload }
    case SET_REQOBJ:
      return { ...state, reqObj: action.payload }
    case SET_REQREJECTI:
      return { ...state, reqRejectI: action.payload }
    case SET_TASKDTLOBJ:
      return { ...state, taskDetailObject: action.payload }
    case SET_TASKDTLFLAG:
      return { ...state, taskDetailFalg: action.payload }
    case SET_MONTHDATA:
      return { ...state, monthData: action.payload }
    case SET_EMPLOADMORE:
      return { ...state, empLoadMore: action.payload }
    case SET_CLOSUREPROCEDURE:
      return { ...state, closureProcedure: action.payload }
    case SET_ISCLOSUREKEY:
      return { ...state, isClosureKey: action.payload }
    case SET_ANALYTICSDATA:
      return { ...state, analyticsData: action.payload }
    case SET_ANALYTICSEMP:
      return { ...state, analyticsEmp: action.payload }
    case SET_ANALYTICSEMPLIST:
      return { ...state, analyticsEmpList: action.payload }
    case SET_DESIGNATIONLIST:
      return { ...state, designationList: action.payload }
    case SET_ISEDITDESIGNATION:
      return { ...state, isEditDesignation: action.payload }
    case SET_ISDETAILANALYTICS:
      return { ...state, isDetailAnalytics: action.payload }
    case SET_ANALYTICSSTARTDATE:
      return { ...state, analyticsStartDate: action.payload }
    case SET_ANALYTICSENDDATE:
      return { ...state, analyticsEndDate: action.payload }
    case SET_LEVELINFOOBJECT:
      return { ...state, levelInfoObject: action.payload }
    case SET_UPDATEPRJFLAG:
      return { ...state, updateProjectFlag: action.payload }
    case SET_REQUESTCOUNT:
      return { ...state, reqCount: action.payload }
    case SET_UPDATE_PROJECT:
      return { ...state, projectToUpadte: action.payload }
    case SET_TASKEMP:
      return { ...state, taskEmp: action.payload }
    case SET_INITIALSTATE:
      return { ...initialState, ...action.payload };
    case SET_EMPJOININGDATE:
      return { ...state, empJoiningDate: action.payload };
    case SET_REQCOUNTDATA:
      return { ...state, reqCountData: action.payload };
    case SET_PRJUPDATEFLAG:
      return { ...state, updatePrjFlagList: action.payload };
    case SET_ANALYTICSTYPE:
      return { ...state, analyticsType: action.payload };
    case SET_INFOSHOW:
      return { ...state, infoShow: action.payload };
    case SET_PRJALLTSKFLAG:
      return { ...state, projectAllTskFlag: action.payload };
    case SET_ISCOMMENTVIEW:
      return { ...state, isCommentView: action.payload };
    case SET_LEAVECLANEDERVIEWFLAG:
      return { ...state, leaveCalenderViewFlag: action.payload }
    case SET_ADDHOLIDAYFLAG:
      return { ...state, addHolidayFlag: action.payload }
    case SET_ADDLEAVEFLAG:
      return { ...state, addLeaveFlag: action.payload }
    case SET_LEAVEDAYVIEWFLAG:
      return { ...state, leaveDayViewFlag: action.payload }
    case SET_SCANNERVIEWFLAG:
      return { ...state, scannerViewFlag: action.payload }
    case SET_ATTENDENCEMONTHVIEWFLAG:
      return { ...state, attendenceMonthViewFlag: action.payload }
    case SET_MYLEAVESTATUSFLAG:
      return { ...state, myLeaveStatusFlag: action.payload }
    case SET_SCANNERATTENDENCELIST:
      return { ...state, scannerAttendenceList: action.payload }
    case SET_ATTENDMUSTEREDFLAG:
      return { ...state, attendenceMusteredFlag: action.payload }
    case SET_ONLEAVEDATE:
      return { ...state, onLeaveDate: action.payload }
    case SET_UPDATELEAVEOBJ:
      return { ...state, updateLeaveObj: action.payload }
    case SET_EDITELEAVEFLAG:
      return { ...state, editeLeaveFlag: action.payload }
    case SET_HOLIDAYLISTFLAG:
      return { ...state, holidayListFlag: action.payload }
    case SET_EDITEHOLIDAY:
      return { ...state, editeHolidayFlag: action.payload }
    case SET_HOLIDAYCRUDOBJ:
      return { ...state, holidayCrudObj: action.payload }
    case SET_MUSTEREDDATE:
      return { ...state, musteredDate: action.payload }
    case SET_MUSTEREDOBJ:
      return { ...state, musterdAttObj: action.payload }
    case SET_EMPISLEAVESTATUS:
      return {...state, empIsLeaveStatus: action.payload}
    case SET_ISHOLIDAYSTATUS : 
      return{...state, isHolidayStatus: action.payload}
    case SET_ATTANALAYTICSDATE:
      return {...state, attendenceAnalyticsDate : action.payload}
    case SET_LEAVELIST:
      return {...state, leaveList: action.payload}
    case SET_LEAVEMANAGEHOLIDAYDAY:
      return {...state, leaveManageHolidayDay : action.payload}
    case SET_ISPRESENTDAY :
      return { ...state, isPresentDay : action.payload}
    case SET_LEVELADDTASKFLAG :
      return {...state, levelAddTaskFlag : action.payload}
    case SET_MONTHADDTASKFLAG :
      return { ...state, monthAddTaskFlag : action.payload}
    case SET_WEEKADDTASKFLAG : 
      return { ...state, weekAddTaskFlag : action.payload}
    case SET_STOREDATEARRAY :
      return { ...state, storeSateArray : action.payload}
    case SET_LEVELMONTHADDTASKFALG :
      return { ...state, levelMonthAddTaskFlag : action.payload}
    default:
      return state;
  }
};
export default reducer;